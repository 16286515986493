import React from "react";
import "rsuite/dist/rsuite.min.css";
import "./App.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./routes/errorPage";
import Users from "./pages/users";
import Customers from "./pages/customers/customers";
import Login from "./pages/login";
import Registration from "./pages/registration";
import { authVerify } from "./functions/auth";
import CreateCustomer from "./pages/customers/createCustomer";
import Articles from "./pages/articles/articles";
import CreateArticle from "./pages/articles/createArticle";
import Districts from "./pages/districts/districts";
import CreateDistrict from "./pages/districts/createDistrict";
import Analyses from "./pages/analyses/analyses";
import CreateAnalyse from "./pages/analyses/createAnalyse";
import DeliveryAddresses from "./pages/deliveryAddresses/deliveryAddresses";
import CreateDeliveryAddress from "./pages/deliveryAddresses/createDeliveryAddress";
import Deliveries from "./pages/deliveries/deliveries";
import CreateDelivery from "./pages/deliveries/createDelivery";
import CreateUser from "./pages/createUser";

const App = () => {
  const router = createBrowserRouter([
    { path: "*", element: <ErrorPage /> },
    {
      path: "/",
      element: <Login />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/register",
      element: <Registration />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/users",
      element: <Users />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/users/create",
      element: <CreateUser />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/customers",
      element: <Customers />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/customers/create",
      element: <CreateCustomer />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/articles",
      element: <Articles />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/articles/create",
      element: <CreateArticle />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/districts",
      element: <Districts />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/districts/create",
      element: <CreateDistrict />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/analyses",
      element: <Analyses />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/analyses/create",
      element: <CreateAnalyse />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/deliveryAddresses",
      element: <DeliveryAddresses />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/deliveryAddresses/create",
      element: <CreateDeliveryAddress />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/deliveries",
      element: <Deliveries />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/deliveries/create",
      element: <CreateDelivery />,
      errorElement: <ErrorPage />,
    },
  ]);

  authVerify();

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
};

export default App;
