import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Button, Loader, Pagination, Table } from "rsuite";
import Content from "../../components/content/content";
import { Search } from "../../components/Search/Search";
import ActionCell from "../../components/Table/ActionCell";
import EditableCell from "../../components/Table/EditableCell";
import {
  deleteDistrict,
  getAllDistricts,
  updateDistrict,
} from "../../functions/districts";
import District from "../../interfaces/district";
import PaginatedResult from "../../interfaces/paginatedResult";

import Root from "../../routes/navigation";

import "./districts.scss";

const Districts = () => {
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const { Column, HeaderCell, Cell } = Table;
  const [paginatedResult, setPaginatedResult] = useState<
    PaginatedResult<District>
  >();

  const [districts, setDistricts] = useState<District[]>([]);

  const [query, setQuery] = useState("");

  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleChange = (id: number, key: number, value: any) => {
    const containsOnlyNumbers = !Array.isArray(value) && /^[0-9]+$/.test(value);

    value = containsOnlyNumbers ? parseInt(value) : value;

    const nextData = Object.assign([], districts);
    //@ts-ignore
    nextData.find((item) => item.id === id)[key] = value;

    setDistricts(nextData);
    getDistrictValues();
    return nextData;
  };

  const handleEditState = (id: number) => {
    const nextData = Object.assign([], districts);
    //@ts-ignore
    const activeItem = nextData.find((item) => item.id === id);
    //@ts-ignore
    activeItem.status = activeItem.status ? null : "EDIT";
    setDistricts(nextData);
    //@ts-ignore
    if (!activeItem.status) {
      handleUpdate(id);
    }
    return nextData;
  };

  useEffect(() => {
    setLoading(true);
    const allDistricts = getAllDistricts(
      page,
      limit,
      query,
      sortType,
      sortColumn
    );
    if (allDistricts) {
      allDistricts
        .then((data: PaginatedResult<District>) => {
          setPaginatedResult(data);

          setDistricts(data.items);

          getDistrictValues();

          return data;
        })
        .catch((error) => {
          console.error(error);
        });
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [page, limit, query, sortColumn, sortType]);

  const handleUpdate = async (id: number) => {
    const districtToUpdate = districts.find((district) => district.id === id);
    const body = {
      name: districtToUpdate!.name,
      zip: districtToUpdate!.zip,
    };
    updateDistrict(id, body);
  };

  const handleDeleteState = async (id: number) => {
    const nextData = Object.assign([], districts);
    //@ts-ignore
    const activeItem = nextData.find((item) => item.id === id);

    nextData.splice(nextData.indexOf(activeItem!), 1);

    setDistricts(nextData);

    await deleteDistrict(id);
  };

  const getSortedData = () => {
    getDistrictValues();
    return districts;
  };

  const getDistrictValues = () => {
    districts.map((district) => {
      if (district.zip && Array.isArray(district.zip)) {
        const string = district.zip.join(", ");
        return Object.assign(district, { zipString: string });
      } else {
        return Object.assign(district, { zipString: district.zip });
      }
    });
  };

  const handleSearchQuery = async (value: string) => {
    setQuery(value);
    setPage(1);
  };

  //@ts-ignore
  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const headers = [
    { label: "Id", key: "id" },
    { label: "Name", key: "name" },
    { label: "PLZ", key: "zip" },
  ];

  const csvReport = {
    data: districts,
    headers: headers,
    filename: "Districts_Export.csv",
  };

  const handleChangeLimit = (dataKey: any) => {
    setPage(1);
    setLimit(dataKey);
  };

  if (!paginatedResult) {
    return (
      <>
        <Root />
        <Content>
          <Loader content='wird geladen...' />
        </Content>
      </>
    );
  } else {
    return (
      <div className='District'>
        <Root />
        <Content>
          <h1>Bezirke</h1>
          <div
            className='actions'
            style={{ marginBottom: 20, display: "flex", gridGap: 20 }}
          >
            <Button href='/districts/create'>Neuen Bezirk anlegen</Button>
            <Search
              onChange={(e) => handleSearchQuery(e)}
              placeholder='Nach Namen suchen'
            />
          </div>
          <div style={{ position: "relative" }}>
            <Table
              virtualized
              data={getSortedData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              cellBordered
              loading={loading}
              autoHeight
            >
              <Column align='center' width={70} sortable>
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey='id' />
              </Column>
              <Column align='center' width={300} sortable>
                <HeaderCell>Name</HeaderCell>
                <EditableCell dataKey='name' onChange={handleChange} />
              </Column>
              <Column align='center' width={300}>
                <HeaderCell>PLZ</HeaderCell>
                <EditableCell
                  dataKey='zipString'
                  datakeyedit='zip'
                  onChange={handleChange}
                />
              </Column>
              <Column width={200}>
                <HeaderCell>Aktionen</HeaderCell>
                <ActionCell
                  dataKey='id'
                  onClick={handleEditState}
                  onDelete={handleDeleteState}
                />
              </Column>
            </Table>
            {loading && <Loader content='wird geladen...' />}
          </div>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size='xs'
              layout={["total", "-", "limit", "|", "pager"]}
              total={paginatedResult!.totalItems}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
          <CSVLink style={{ marginTop: 8 }} {...csvReport}>
            Ansicht als CSV herunterladen
          </CSVLink>
        </Content>
      </div>
    );
  }
};

export default Districts;
