import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  ButtonToolbar,
  InputGroup,
  FlexboxGrid,
  Panel,
} from "rsuite";

import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";

import "./login.scss";
import FormGroup from "rsuite/esm/FormGroup";
import FormControl from "rsuite/esm/FormControl";
import { signIn } from "../functions/auth";

const Login = () => {
  const [visible, setVisible] = useState(false);

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const handlePasswordVisibility = () => {
    setVisible(!visible);
  };

  const handleSubmit = async () => {
    await signIn(email, password);
  };

  return (
    <div className='login'>
      <FlexboxGrid justify='center'>
        <FlexboxGrid.Item
          colspan={4}
          style={{ background: "#fff", marginTop: "7%", borderRadius: "10px" }}
        >
          <Panel bordered style={{ borderRadius: "10px" }}>
            <Form fluid onSubmit={() => handleSubmit()}>
              <FormGroup>
                <Form.ControlLabel>Email</Form.ControlLabel>
                <FormControl
                  name='email'
                  type='email'
                  onChange={(e) => setEmail(e)}
                />
              </FormGroup>
              <FormGroup>
                <Form.ControlLabel>Password</Form.ControlLabel>
                <InputGroup inside>
                  <Input
                    type={visible ? "text" : "password"}
                    onChange={(e) => setPassword(e)}
                  />
                  <InputGroup.Button onClick={handlePasswordVisibility}>
                    {visible ? <EyeIcon /> : <EyeSlashIcon />}
                  </InputGroup.Button>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <ButtonToolbar style={{ justifyContent: "flex-end" }}>
                  <Button appearance='link' href='/register'>
                    Neu registrieren
                  </Button>
                  <Button appearance='primary' type='submit'>
                    Anmelden
                  </Button>
                  <Button appearance='link'>Passwort vergessen?</Button>
                </ButtonToolbar>
              </FormGroup>
            </Form>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
};

export default Login;
