import React from "react";
import { Message, toaster } from "rsuite";

const api = process.env.REACT_APP_API_URL ?? "http://localhost:8080";

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const authVerify = async () => {
  if (localStorage.getItem("accessToken")) {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const decodedJwt = parseJwt(accessToken);

      if (decodedJwt.exp * 1000 < Date.now()) {
        signOut();
      }
    }
  }
};

export const signIn = async (email: string, password: string) => {
  try {
    const body = { email: email, password: password };
    const login = await fetch(`${api}/api/auth/signin`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    if (login.ok) {
      const data = await login.json();
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("user", data.id);
      localStorage.setItem("isAdmin", data.isAdmin);
      toaster.push(
        <Message showIcon type='success' header='Erfolgreich'>
          Sie sind nun als {data.name} eingeloggt.
        </Message>
      );
      window.location.href = "/analyses";
    }
  } catch (e) {
    console.log(e);
    toaster.push(
      <Message showIcon type='error' header='Fehlgeschlagen'>
        Leider ist etwas schiefgelaufen. Bitte überprüfen Sie E-Mail Adresse und
        Passwort.
      </Message>
    );
    return;
  }
};

export const signOut = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("user");
  window.location.href = "/";
};
