import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Button, Loader, Pagination, Table } from "rsuite";
import Content from "../../components/content/content";
import { Search } from "../../components/Search/Search";
import ActionCell from "../../components/Table/ActionCell";
import EditableCell from "../../components/Table/EditableCell";
import {
  deleteArticle,
  getAllArticles,
  updateArticle,
} from "../../functions/articles";
import Article from "../../interfaces/article";
import PaginatedResult from "../../interfaces/paginatedResult";

import Root from "../../routes/navigation";

import "./articles.scss";

const Articles = () => {
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const { Column, HeaderCell, Cell } = Table;
  const [articles, setArticles] = useState<Article[]>([]);

  const [paginatedResult, setPaginatedResult] = useState<
    PaginatedResult<Article>
  >();

  const [query, setQuery] = useState("");

  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleChange = (id: number, key: number, value: any) => {
    const nextData = Object.assign([], articles);
    //@ts-ignore
    nextData.find((item) => item.id === id)[key] = value;
    setArticles(nextData);
  };

  const handleEditState = (id: number) => {
    const nextData = Object.assign([], articles);
    //@ts-ignore
    const activeItem = nextData.find((item) => item.id === id);
    //@ts-ignore
    activeItem.status = activeItem.status ? null : "EDIT";
    setArticles(nextData);
    //@ts-ignore
    if (!activeItem.status) {
      handleUpdate(id);
    }
  };

  useEffect(() => {
    setLoading(true);
    const allArticles = getAllArticles(
      page,
      limit,
      query,
      sortType,
      sortColumn
    );
    if (allArticles) {
      allArticles
        .then((data: PaginatedResult<Article>) => {
          setPaginatedResult(data);
          setArticles(data.items);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [page, limit, query, sortColumn, sortType]);

  const handleUpdate = async (id: number) => {
    const articleToUpdate = articles.find((article) => article.id === id);
    const body = {
      name: articleToUpdate!.name,
      shortName: articleToUpdate!.shortName,
    };
    updateArticle(id, body);
  };

  const handleDeleteState = async (id: number) => {
    const nextData = Object.assign([], articles);
    //@ts-ignore
    const activeItem = nextData.find((item) => item.id === id);

    nextData.splice(nextData.indexOf(activeItem!), 1);

    setArticles(nextData);

    await deleteArticle(id);
  };

  const getSortedData = () => {
    return articles;
  };

  const handleSearchQuery = async (value: string) => {
    setQuery(value);
    setPage(1);
  };

  //@ts-ignore
  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const headers = [
    { label: "Id", key: "id" },
    { label: "Name", key: "name" },
    { label: "Kurzbezeichung", key: "shortName" },
  ];

  const csvReport = {
    data: articles,
    headers: headers,
    filename: "Articles_Export.csv",
  };

  const handleChangeLimit = (dataKey: any) => {
    setPage(1);
    setLimit(dataKey);
  };

  if (!paginatedResult) {
    return (
      <>
        <Root />
        <Content>
          <Loader content='wird geladen...' />
        </Content>
      </>
    );
  } else {
    return (
      <div className='Article'>
        <Root />
        <Content>
          <h1>Artikel</h1>
          <div
            className='actions'
            style={{ marginBottom: 20, display: "flex", gridGap: 20 }}
          >
            <Button href='/articles/create'>Neuen Artikel anlegen</Button>
            <Search
              onChange={(e) => handleSearchQuery(e)}
              placeholder='Nach Namen suchen'
            />
          </div>
          <div>
            <Table
              virtualized
              data={getSortedData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              cellBordered
              loading={loading}
              autoHeight
            >
              <Column align='center' width={70} sortable>
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey='id' />
              </Column>
              <Column align='center' width={300} sortable>
                <HeaderCell>Name</HeaderCell>
                <EditableCell dataKey='name' onChange={handleChange} />
              </Column>
              <Column align='center' width={300}>
                <HeaderCell>Kurzbezeichnung</HeaderCell>
                <EditableCell dataKey='shortName' onChange={handleChange} />
              </Column>
              <Column width={200}>
                <HeaderCell>Aktionen</HeaderCell>
                <ActionCell
                  dataKey='id'
                  onClick={handleEditState}
                  onDelete={handleDeleteState}
                />
              </Column>
            </Table>
            {loading && <Loader content='wird geladen...' />}
          </div>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size='xs'
              layout={["total", "-", "limit", "|", "pager"]}
              total={paginatedResult!.totalItems}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
          <CSVLink style={{ marginTop: 8 }} {...csvReport}>
            Ansicht als CSV herunterladen
          </CSVLink>
        </Content>
      </div>
    );
  }
};

export default Articles;
