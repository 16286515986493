import React from "react";

import "./index.css";
import App from "./App";

import { createRoot } from "react-dom/client";

// import env from "react-dotenv";

// (window as any).apiURL = env.REACT_APP_API_URL;

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);
