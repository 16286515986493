import React, { useEffect, useState } from "react";
import { Button, ButtonToolbar, DatePicker, Form, Modal } from "rsuite";
import Content from "../../components/content/content";
import Picker from "../../components/Picker/Picker";
import { createAnalyse } from "../../functions/analyses";
import { getAllArticles } from "../../functions/articles";
import Article from "../../interfaces/article";

import Root from "../../routes/navigation";
import DeliveryAddress from "../../interfaces/deliveryAddress";
import { getAllDeliveryAddresses } from "../../functions/deliveryAddresses";
import CreateDeliveryAddress from "../deliveryAddresses/createDeliveryAddress";

const CreateAnalyse = () => {
  const [date, setDate] = useState<Date>(new Date(Date.now()));
  const [analyseNumber, setAnalyseNumber] = useState<string>();
  const [deliveryAddressId, setDeliveryAddressId] = useState<number>();
  const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);
  const [maxQuantity, setMaxQuantity] = useState<string>();

  const [articles, setArticles] = useState<Article[]>([]);

  const [deliveryAddresses, setDeliveryAddresses] = useState<DeliveryAddress[]>(
    []
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    const body = {
      date: date,
      analyseNumber: analyseNumber!,
      deliveryAddressId: deliveryAddressId!,
      articles: selectedArticles,
      maxQuantity: parseFloat(maxQuantity!.replace(",", ".")),
      createdBy: currentUser,
    };
    await createAnalyse(body);
    window.location.pathname = "/analyses";
  };

  const currentUser: number = parseInt(localStorage.getItem("user")!) || 0;

  useEffect(() => {
    getAllArticles(1, 99999)
      .then((data) => {
        setArticles(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
    getAllDeliveryAddresses(1, 99999)
      .then((data) => {
        setDeliveryAddresses(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  }, [open]);

  return (
    <div className='Analyse'>
      <Root />
      <Content>
        <h1>Neue Analyse anlegen</h1>

        <Form layout='horizontal' onSubmit={handleSubmit}>
          <Form.Group>
            <Form.ControlLabel>Datum *</Form.ControlLabel>
            <DatePicker
              placeholder='Datum'
              name='date'
              defaultValue={date}
              onChange={(e) => (e !== null ? setDate(e) : undefined)}
              format='dd.MM.yyyy'
              style={{ width: "300px" }}
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>Analyse Nummer *</Form.ControlLabel>
            <Form.Control
              placeholder='Analyse Nummer'
              name='analyseNumber'
              onChange={(e) => setAnalyseNumber(e)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>Herkunftsadresse *</Form.ControlLabel>
            <Picker
              placeholder='Herkunftsadresse'
              data={deliveryAddresses! as []}
              defaultValue={""}
              onChange={(e) => {
                setDeliveryAddressId(e);
              }}
              style={{ width: "300px" }}
            />
            <br />
            <a
              onClick={handleOpen}
              style={{
                fontSize: "12px",
                marginTop: "5px",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              Neue Herkunftsadresse anlegen
            </a>
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>Artikel *</Form.ControlLabel>
            <Picker
              placeholder='Artikel'
              data={articles as []}
              defaultValue={[]}
              multiple={true}
              onChange={(e) => {
                setSelectedArticles(e);
              }}
              style={{ width: "300px" }}
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>Maximale Menge *</Form.ControlLabel>
            <Form.Control
              placeholder='Maximale Menge'
              name='maxQuantity'
              onChange={(e) => setMaxQuantity(e)}
              required
            />
          </Form.Group>

          <Form.Group>
            <ButtonToolbar>
              <Button appearance='primary' type='submit'>
                Absenden
              </Button>
              <Button appearance='default' href='/analyses'>
                Abbrechen
              </Button>
            </ButtonToolbar>
          </Form.Group>

          <Modal open={open} onClose={handleClose}>
            <Modal.Header>
              <Modal.Title>Neue Herkunftsadresse anlegen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateDeliveryAddress withoutSidebar={true} />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose} appearance='subtle'>
                Dialog schließen
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      </Content>
    </div>
  );
};

export default CreateAnalyse;
