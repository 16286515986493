import React from "react";
import { Message, toaster } from "rsuite";
import AnalyseDto from "../interfaces/analyseDto";
import Analyse from "../interfaces/analyse";
import DeliveryAddress from "../interfaces/deliveryAddress";

const api = process.env.REACT_APP_API_URL ?? "http://localhost:8080";

export const getAllAnalyses = async (
  page: number,
  limit: number,
  query?: string,
  sortType?: string,
  sortColumn?: string
) => {
  return await fetch(
    `${api}/api/analyses?page=${page - 1}&size=${limit}${
      query !== "" && query !== undefined ? `&query=${query}` : ""
    }${
      sortType !== undefined &&
      sortType !== null &&
      sortColumn !== undefined &&
      sortColumn !== null
        ? `&sort[${sortColumn}]=${sortType}`
        : ""
    }
  `,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("accessToken")}`,
      },
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error(`Something went wrong, ${JSON.stringify(res)}`);
      }
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const createAnalyse = async (body: AnalyseDto) => {
  return await fetch(`${api}/api/analyses/createAnalyse`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${localStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      if (res.ok) {
        const data = await res.json();
        toaster.push(
          <Message showIcon type='success' header='Erfolgreich'>
            Die Analyse {data.name} wurde erfolgreich gespeichert.
          </Message>
        );
      }
    })
    .catch((e) => {
      console.log(e);
      toaster.push(
        <Message showIcon type='error' header='Fehlgeschlagen'>
          Leider ist beim Speichern der Analyse etwas schiefgelaufen.
        </Message>
      );
      return;
    });
};

export const updateAnalyse = async (id: number, body: AnalyseDto) => {
  try {
    const analyse = await fetch(`${api}/api/analyses/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(body),
    });

    if (analyse.ok) {
      const data = await analyse.json();
      toaster.push(
        <Message showIcon type='success' header='Erfolgreich'>
          Die Analyse {data.name} wurde erfolgreich gespeichert.
        </Message>
      );
    }
  } catch (e) {
    console.log(e);
    toaster.push(
      <Message showIcon type='error' header='Fehlgeschlagen'>
        Leider ist beim Speichern der Analyse etwas schiefgelaufen.
      </Message>
    );
    return;
  }
};

export const deleteAnalyse = async (id: number) => {
  try {
    const deleteAnalyse = await fetch(`${api}/api/analyses/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("accessToken")}`,
      },
    });
    if (deleteAnalyse.ok) {
      const data = await deleteAnalyse.json();
      toaster.push(
        <Message showIcon type='success' header='Erfolgreich'>
          Die Analyse {data.name} wurde erfolgreich gelöscht.
        </Message>
      );
    }
  } catch (e) {
    console.log(e);
    toaster.push(
      <Message showIcon type='error' header='Fehlgeschlagen'>
        Leider ist beim Löschen der Analyse etwas schiefgelaufen.
      </Message>
    );
    return;
  }
};

export const updateAnalyseById = async (id: number, body: AnalyseDto) => {
  try {
    const analyse = await fetch(`${api}/api/analyses/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(body),
    });

    if (analyse.ok) {
      const data = await analyse.json();
      return data;
    }
  } catch (e) {
    console.log(e);

    return;
  }
};

export const createPDF = async (
  analyse: Analyse,
  deliveryAddress: DeliveryAddress
) => {
  const body = {
    analyse,
    deliveryAddress,
  };
  return await fetch(`${api}/api/pdf/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${localStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify(body),
  });
};

export const uploadPDF = async (analyse: Analyse) => {
  return await fetch(`${api}/api/pdf/upload`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${localStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify(analyse),
  });
};

export const getPDF = async (fileName: string) => {
  return await fetch(`${api}/api/pdf/get/${fileName}`, {
    method: "GET",
  })
    .then((res) => {
      if (res.ok) {
        return res;
      }
    })
    .catch((e) => {
      throw new Error(e);
    });
};
