import React from "react";
import { Message, toaster } from "rsuite";
import DistrictDto from "../interfaces/districtDto";

const api = process.env.REACT_APP_API_URL ?? "http://localhost:8080";

export const getAllDistricts = async (
  page: number,
  limit: number,
  query?: string,
  sortType?: string,
  sortColumn?: string
) => {
  return await fetch(
    `${api}/api/districts?page=${page - 1}&size=${limit}${
      query !== "" && query !== undefined ? `&query=${query}` : ""
    }${
      sortType !== undefined &&
      sortType !== null &&
      sortColumn !== undefined &&
      sortColumn !== null
        ? `&sort[${sortColumn}]=${sortType}`
        : ""
    }
    `,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("accessToken")}`,
      },
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error(`Something went wrong, ${JSON.stringify(res)}`);
      }
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const createDistrict = async (body: DistrictDto) => {
  return await fetch(`${api}/api/districts/createDistrict`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${localStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      if (res.ok) {
        const data = await res.json();
        toaster.push(
          <Message showIcon type='success' header='Erfolgreich'>
            Der Bezirk {data.name} wurde erfolgreich gespeichert.
          </Message>
        );
      }
    })
    .catch((e) => {
      console.log(e);
      toaster.push(
        <Message showIcon type='error' header='Fehlgeschlagen'>
          Leider ist beim Speichern des Bezirks etwas schiefgelaufen.
        </Message>
      );
      return;
    });
};

export const updateDistrict = async (id: number, body: DistrictDto) => {
  try {
    const district = await fetch(`${api}/api/districts/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(body),
    });

    if (district.ok) {
      const data = await district.json();
      toaster.push(
        <Message showIcon type='success' header='Erfolgreich'>
          Der Bezirk {data.name} wurde erfolgreich gespeichert.
        </Message>
      );
    }
  } catch (e) {
    console.log(e);
    toaster.push(
      <Message showIcon type='error' header='Fehlgeschlagen'>
        Leider ist beim Speichern des Bezirks etwas schiefgelaufen.
      </Message>
    );
    return;
  }
};

export const deleteDistrict = async (id: number) => {
  try {
    const deleteDistrict = await fetch(`${api}/api/districts/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("accessToken")}`,
      },
    });
    if (deleteDistrict.ok) {
      const data = await deleteDistrict.json();
      toaster.push(
        <Message showIcon type='success' header='Erfolgreich'>
          Der Bezirk {data.name} wurde erfolgreich gelöscht.
        </Message>
      );
    }
  } catch (e) {
    console.log(e);
    toaster.push(
      <Message showIcon type='error' header='Fehlgeschlagen'>
        Leider ist beim Löschen des Bezirks etwas schiefgelaufen.
      </Message>
    );
    return;
  }
};
