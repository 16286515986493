import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Button, Loader, Pagination, Table } from "rsuite";
import Content from "../../components/content/content";
import { Search } from "../../components/Search/Search";
import ActionCell from "../../components/Table/ActionCell";
import EditableCell from "../../components/Table/EditableCell";
import {
  deleteAnalyse,
  getAllAnalyses,
  updateAnalyse,
} from "../../functions/analyses";
import { getAllArticles } from "../../functions/articles";
import { createPDF, getPDF, uploadPDF } from "../../functions/analyses";
import { getAllUsers } from "../../functions/users";
import Analyse from "../../interfaces/analyse";
import Article from "../../interfaces/article";
import PaginatedResult from "../../interfaces/paginatedResult";
import User from "../../interfaces/user";

import Root from "../../routes/navigation";

import "./analyses.scss";
import DeliveryAddress from "../../interfaces/deliveryAddress";
import { getAllDeliveryAddresses } from "../../functions/deliveryAddresses";

const Analyses = () => {
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const [query, setQuery] = useState("");

  const { Column, HeaderCell, Cell } = Table;
  const [analyses, setAnalyses] = useState<Analyse[]>([]);
  const [articles, setArticles] = useState<Article[]>([]);
  const [deliveryAddresses, setDeliveryAddresses] = useState<DeliveryAddress[]>(
    []
  );
  const [users, setUsers] = useState<User[]>([]);

  const [paginatedResult, setPaginatedResult] = useState<
    PaginatedResult<Analyse>
  >();

  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleChange = (id: number, key: any, value: any) => {
    const nextData = Object.assign([], analyses);

    //@ts-ignore
    nextData!.find((item: Analyse) => item.id === id)[key] = value;

    if (key === "dkAccepted") {
      //@ts-ignore
      nextData.find((item) => item.id === id)["dkState"] =
        value.toString() === "true" ? "Ja" : "Nein";
    }

    setAnalyses(nextData);
    getAnalyseValues();
  };

  const handleDownloadState = async (id: number) => {
    analyses.find((item: Analyse) => item.id === id)!["charakterization"] = `${
      analyses.find((item: Analyse) => item.id === id)!.analyseNumber
    }-${new Date().getTime()}.pdf`;

    handleUpdate(id);

    const analyse = analyses.find((item: Analyse) => item.id === id);

    const deliveryAddress = deliveryAddresses.find(
      (item: DeliveryAddress) => item.id === analyse!.deliveryAddressId
    );

    console.log("analyse :>> ", analyse);

    await createPDF(analyse!, deliveryAddress!);
    await uploadPDF(analyse!);
    await getPDF(analyse!.charakterization!);
    await openDocument(analyse!);
  };

  const openDocument = async (analyse: Analyse) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/api/pdf/get/${analyse.charakterization}`
    );
  };

  const handleEditState = (id: number) => {
    const nextData = Object.assign([], analyses);
    //@ts-ignore
    const activeItem = nextData.find((item) => item.id === id);

    //@ts-ignore
    activeItem.status = activeItem.status ? null : "EDIT";
    setAnalyses(nextData);
    //@ts-ignore
    if (!activeItem.status) {
      handleUpdate(id);
    }
  };

  const handleDeleteState = async (id: number) => {
    const nextData = Object.assign([], analyses);
    //@ts-ignore
    const activeItem = nextData.find((item) => item.id === id);

    nextData.splice(nextData.indexOf(activeItem!), 1);

    setAnalyses(nextData);

    await deleteAnalyse(id);
  };

  useEffect(() => {
    getAllArticles(1, 99999)
      .then((data: PaginatedResult<Article>) => {
        setArticles(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });

    getAllUsers(1, 99999)
      .then((data: PaginatedResult<User>) => {
        setUsers(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });

    getAllDeliveryAddresses(1, 99999)
      .then((data: PaginatedResult<DeliveryAddress>) => {
        setDeliveryAddresses(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const allAnalyses = getAllAnalyses(
      page,
      limit,
      query,
      sortType,
      sortColumn
    );
    if (allAnalyses) {
      allAnalyses
        .then((data: PaginatedResult<Analyse>) => {
          setPaginatedResult(data);
          setAnalyses(data.items);

          data.items.map((item: Analyse) => {
            Object.assign(item, {
              dkState: item.dkAccepted?.toString() === "true" ? "Ja" : "Nein",
            });
            // @ts-ignore
            const articles = JSON.parse(item.articles);
            const articlesArray = [];
            articles.map((item: number) => {
              articlesArray.push(item);
            });
            item.articles = articles;
          });
          return data;
        })
        .catch((error) => {
          console.error(error);
        });
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [page, limit, query, sortType, sortColumn]);

  const pattern = /^[0-9,]*$/g;

  const handleUpdate = async (id: number) => {
    const analyseToUpdate = analyses.find((analyse) => analyse.id === id);
    const body = {
      date: analyseToUpdate!.date,
      deliveryAddressId: analyseToUpdate!.deliveryAddressId,
      analyseNumber: analyseToUpdate!.analyseNumber,
      articles: analyseToUpdate!.articles,
      maxQuantity: analyseToUpdate!.maxQuantity.toString().includes(",")
        ? parseFloat(analyseToUpdate!.maxQuantity.replace(",", "."))
        : parseFloat(analyseToUpdate!.maxQuantity),
      createdBy: analyseToUpdate!.createdBy,
      texture: analyseToUpdate!.texture,
      color: analyseToUpdate!.color,
      smell: analyseToUpdate!.smell,
      dkAccepted: analyseToUpdate!.dkAccepted,
      pretreatment: analyseToUpdate!.pretreatment,
      charakterization: analyseToUpdate!.charakterization,
    };
    updateAnalyse(id, body);
  };

  const getSortedData = () => {
    getAnalyseValues();

    return analyses;
  };

  const getAnalyseValues = () => {
    analyses.map((analyse) => {
      if (
        analyse.deliveredQuantity &&
        typeof analyse.deliveredQuantity === "number"
      ) {
        const netWeightNumber = parseFloat(analyse.deliveredQuantity)
          .toFixed(2)
          .replace(".", ",");

        analyse.deliveredQuantity = netWeightNumber;
      }
      if (analyse.maxQuantity && typeof analyse.maxQuantity === "number") {
        const netWeightNumber = parseFloat(analyse.maxQuantity)
          .toFixed(2)
          .replace(".", ",");

        analyse.maxQuantity = netWeightNumber;
      }
      const articleName: string[] = [];
      if (analyse.articles && Array.isArray(analyse.articles)) {
        analyse.articles.map((i) => {
          articles.find((article) => {
            //@ts-ignore
            if (article.id === i) {
              articleName.push(article.name);
            }
          });
        });
        Object.assign(analyse, { article: articleName.join(", ") });
      } else {
        articles.find((article) => {
          //@ts-ignore
          if (article.id === article.articles) {
            Object.assign(analyse, { article: article.name });
          }
        });
      }
      deliveryAddresses.find((deliveryAddress) => {
        if (deliveryAddress.id === analyse.deliveryAddressId) {
          if (analyse.deliveryAddress)
            analyse["deliveryAddress"] = deliveryAddress.name;
          else
            Object.assign(analyse, {
              deliveryAddress: deliveryAddress.name,
            });
          if (analyse.deliveryAddressName)
            analyse["deliveryAddressName"] =
              deliveryAddress.deliveryAddressName;
          else
            Object.assign(analyse, {
              deliveryAddressName: deliveryAddress.deliveryAddressName,
            });
        }
      });
      users.find((user) => {
        if (user.id === analyse.createdBy) {
          if (analyse.createdByUser) {
            analyse["createdByUser"] = user.name;
          } else {
            Object.assign(analyse, { createdByUser: user.name });
          }
        }
      });
      Object.assign(analyse, {
        formattedDate: format(new Date(analyse.date), "dd.MM.yyyy"),
      });
      return analyse;
    });
  };

  const handleSearchQuery = async (value: string) => {
    setQuery(value);
    setPage(1);
  };

  const handleChangeLimit = (dataKey: any) => {
    setPage(1);
    setLimit(dataKey);
  };

  //@ts-ignore
  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);

      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const headers = [
    { label: "Id", key: "id" },
    { label: "Datum", key: "formattedDate" },
    { label: "Analyse Nummer", key: "analyseNumber" },
    { label: "Herkunftsadresse", key: "deliveryAddress" },
    { label: "Artikel", key: "article" },
    { label: "Gelieferte Menge", key: "deliveredQuantity" },
    { label: "Maximale Menge", key: "maxQuantity" },
    { label: "Vorbehandlung", key: "pretreatment" },
    { label: "Konsistenz", key: "texture" },
    { label: "Geruch", key: "smell" },
    { label: "Farbe", key: "color" },
    { label: "DK 0 eingehalten?", key: "dkAccepted" },
    { label: "Erstellt von", key: "createdByUser" },
  ];

  const csvReport = {
    data: analyses,
    headers: headers,
    filename: "Analyses_Export.csv",
  };

  if (!paginatedResult) {
    return (
      <>
        <Root />
        <Content>
          <Loader content='wird geladen...' />
        </Content>
      </>
    );
  } else {
    return (
      <div className='Analyse'>
        <Root />
        <Content>
          <h1>Analysen</h1>
          <div
            className='actions'
            style={{ marginBottom: 20, display: "flex", gridGap: 20 }}
          >
            <Button href='/analyses/create'>Neue Analyse anlegen</Button>
            <Search
              onChange={(e) => handleSearchQuery(e)}
              placeholder='Nach Analysenummer suchen'
            />
          </div>
          <div>
            <Table
              virtualized
              data={getSortedData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              cellBordered
              autoHeight
            >
              <Column align='center' width={70} sortable>
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey='id' />
              </Column>
              <Column align='center' width={150}>
                <HeaderCell>Datum</HeaderCell>
                <EditableCell
                  dataKey='formattedDate'
                  datakeyedit='date'
                  datepicker={true}
                  onChange={handleChange}
                />
              </Column>
              <Column align='center' width={120}>
                <HeaderCell>Analyse Nummer</HeaderCell>
                <EditableCell dataKey='analyseNumber' onChange={handleChange} />
              </Column>
              <Column align='center' width={200} resizable>
                <HeaderCell>Herkunftsadresse</HeaderCell>
                <EditableCell
                  dataKey='deliveryAddress'
                  datakeyedit='deliveryAddressId'
                  usepicker={true}
                  pickerdata={deliveryAddresses}
                  onChange={handleChange}
                />
              </Column>
              <Column align='center' width={150} resizable>
                <HeaderCell>Artikel</HeaderCell>
                <EditableCell
                  dataKey='article'
                  datakeyedit='articles'
                  usepicker={true}
                  pickerdata={articles}
                  multiple={true}
                  onChange={handleChange}
                />
              </Column>
              <Column align='center' width={120}>
                <HeaderCell>Gelieferte Menge</HeaderCell>
                <Cell dataKey='deliveredQuantity' />
              </Column>
              <Column align='center' width={120}>
                <HeaderCell>Maximale Menge</HeaderCell>
                <EditableCell dataKey='maxQuantity' onChange={handleChange} />
              </Column>
              <Column align='center' width={120}>
                <HeaderCell>Vorbehandlung</HeaderCell>
                <EditableCell dataKey='pretreatment' onChange={handleChange} />
              </Column>
              <Column align='center' width={120}>
                <HeaderCell>Konsistenz</HeaderCell>
                <EditableCell dataKey='texture' onChange={handleChange} />
              </Column>
              <Column align='center' width={120}>
                <HeaderCell>Geruch</HeaderCell>
                <EditableCell dataKey='smell' onChange={handleChange} />
              </Column>
              <Column align='center' width={120}>
                <HeaderCell>Farbe</HeaderCell>
                <EditableCell dataKey='color' onChange={handleChange} />
              </Column>
              <Column align='center' width={120}>
                <HeaderCell>DK 0 eingehalten?</HeaderCell>
                <EditableCell
                  datakeyedit='dkAccepted'
                  dataKey='dkState'
                  onChange={handleChange}
                  usecheckbox={true}
                />
              </Column>
              <Column align='center' width={150}>
                <HeaderCell>Erstellt von</HeaderCell>
                <Cell dataKey='createdByUser' />
              </Column>
              <Column width={300}>
                <HeaderCell>Aktionen</HeaderCell>
                <ActionCell
                  dataKey='id'
                  onClick={handleEditState}
                  onDelete={handleDeleteState}
                  onDownload={handleDownloadState}
                />
              </Column>
            </Table>
            {loading && <Loader content='wird geladen...' />}
          </div>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size='xs'
              layout={["total", "-", "limit", "|", "pager"]}
              total={paginatedResult!.totalItems}
              limitOptions={[10, 30, 50, 9999]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
          <CSVLink style={{ marginTop: 8 }} {...csvReport}>
            Ansicht als CSV herunterladen
          </CSVLink>
        </Content>
      </div>
    );
  }
};

export default Analyses;
