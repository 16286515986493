import React from "react";
import { Message, toaster } from "rsuite";
import CustomerDto from "../interfaces/customerDto";

const api = process.env.REACT_APP_API_URL ?? "http://localhost:8080";

export const getAllCustomers = async (
  page: number,
  limit: number,
  query?: string,
  sortType?: string,
  sortColumn?: string
) => {
  return await fetch(
    `${api}/api/customers?page=${page - 1}&size=${limit}${
      query !== "" && query !== undefined ? `&query=${query}` : ""
    }${
      sortType !== undefined &&
      sortType !== null &&
      sortColumn !== undefined &&
      sortColumn !== null
        ? `&sort[${sortColumn}]=${sortType}`
        : ""
    }
    `,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("accessToken")}`,
      },
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error(`Something went wrong, ${JSON.stringify(res)}`);
      }
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const createCustomer = async (body: CustomerDto) => {
  return await fetch(`${api}/api/customers/createCustomer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${localStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify(body),
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error(`Something went wrong, ${JSON.stringify(res)}`);
      }
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const updateCustomer = async (id: number, body: CustomerDto) => {
  try {
    const customer = await fetch(`${api}/api/customers/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(body),
    });

    if (customer.ok) {
      const data = await customer.json();
      toaster.push(
        <Message showIcon type='success' header='Erfolgreich'>
          Der User {data.name} wurde erfolgreich gespeichert.
        </Message>
      );
    }
  } catch (e) {
    console.log(e);
    toaster.push(
      <Message showIcon type='error' header='Fehlgeschlagen'>
        Leider ist beim Speichern des Users etwas schiefgelaufen.
      </Message>
    );
    return;
  }
};

export const deleteCustomer = async (id: number) => {
  try {
    const deleteCustomer = await fetch(`${api}/api/customers/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("accessToken")}`,
      },
    });
    if (deleteCustomer.ok) {
      const data = await deleteCustomer.json();
      toaster.push(
        <Message showIcon type='success' header='Erfolgreich'>
          Der Customer {data.name} wurde erfolgreich gelöscht.
        </Message>
      );
    }
  } catch (e) {
    console.log(e);
    toaster.push(
      <Message showIcon type='error' header='Fehlgeschlagen'>
        Leider ist beim Löschen des Customers etwas schiefgelaufen.
      </Message>
    );
    return;
  }
};
