import React from "react";
import { DatePicker, Table, TagInput } from "rsuite";
import Picker from "../Picker/Picker";

const { Cell } = Table;

const EditableCell = ({ ...props }) => {
  const editing = props.rowData.status === "EDIT";

  const getCurrentDate = (currentValue: any) => {
    if (currentValue === undefined || currentValue === null) {
      const val = new Date(Date.now());
      return val;
    } else {
      const val = new Date(currentValue);
      return val;
    }
  };

  return (
    <Cell {...props} className={editing ? "table-content-editing" : ""}>
      {editing ? (
        <>
          {props.datakeyedit && props.datakeyedit === "zip" && (
            <TagInput
              trigger={["Enter", "Space", "Comma"]}
              placeholder='Enter, Space, Comma'
              style={{ width: 300 }}
              menuStyle={{ width: 300 }}
              defaultValue={
                props.rowData[
                  props.datakeyedit ? props.datakeyedit : props.dataKey
                ]
              }
              onChange={(event) => {
                props.onChange &&
                  props.onChange(
                    props.rowData.id,
                    props.datakeyedit ? props.datakeyedit : props.dataKey,
                    event
                  );
              }}
            />
          )}

          {!props.usepicker && !props.datepicker && !props.usecheckbox && (
            <input
              className='rs-input'
              defaultValue={
                props.rowData[
                  props.datakeyedit ? props.datakeyedit : props.dataKey
                ]
              }
              onChange={(event) => {
                props.onChange &&
                  props.onChange(
                    props.rowData.id,
                    props.datakeyedit ? props.datakeyedit : props.dataKey,
                    event.target.value
                  );
              }}
            />
          )}
          {props.datepicker && (
            <>
              <DatePicker
                value={getCurrentDate(
                  props.rowData[
                    props.datakeyedit ? props.datakeyedit : props.dataKey
                  ]
                )}
                cleanable={false}
                format='dd.MM.yyyy'
                onChange={(e) => {
                  props.onChange &&
                    props.onChange(
                      props.rowData.id,
                      props.datakeyedit ? props.datakeyedit : props.dataKey,
                      e
                    );
                }}
              />
            </>
          )}
          {props.usepicker && !props.multiple && (
            <Picker
              data={props.pickerdata}
              defaultValue={
                props.rowData[
                  props.datakeyedit ? props.datakeyedit : props.dataKey
                ]
              }
              onChange={(e) => {
                props.onChange &&
                  props.onChange(
                    props.rowData.id,
                    props.datakeyedit ? props.datakeyedit : props.dataKey,
                    e
                  );
              }}
            />
          )}

          {props.usepicker && props.multiple && (
            <Picker
              data={props.pickerdata}
              defaultValue={
                props.rowData[
                  props.datakeyedit ? props.datakeyedit : props.dataKey
                ]
              }
              multiple={true}
              onChange={(e) => {
                props.onChange &&
                  props.onChange(
                    props.rowData.id,
                    props.datakeyedit ? props.datakeyedit : props.dataKey,
                    e
                  );
              }}
            />
          )}

          {props.usecheckbox && (
            <input
              type='checkbox'
              defaultChecked={
                props.rowData[
                  props.datakeyedit ? props.datakeyedit : props.dataKey
                ]
              }
              onChange={(e: any) => {
                props.onChange &&
                  props.onChange(
                    props.rowData.id,
                    props.datakeyedit ? props.datakeyedit : props.dataKey,
                    e.target.checked
                  );
              }}
            />
          )}
        </>
      ) : (
        <span className='table-content-edit-span'>
          {props.rowData[props.dataKey]}
        </span>
      )}
    </Cell>
  );
};

export default EditableCell;
