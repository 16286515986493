import React, { useState } from "react";
import { Button, ButtonToolbar, Form, TagInput } from "rsuite";
import Content from "../../components/content/content";
import { createDistrict } from "../../functions/districts";

import Root from "../../routes/navigation";

const CreateDistrict = () => {
  const [name, setName] = useState("");
  const [zip, setZip] = useState<Array<string>>([]);

  const handleSubmit = async () => {
    const body = {
      name: name,
      zip: zip,
    };
    await createDistrict(body);
    setTimeout(() => {
      window.location.pathname = "/districts";
    }, 500);
  };

  return (
    <div className='District'>
      <Root />
      <Content>
        <h1>Neuen Bezirk anlegen</h1>

        <Form layout='horizontal' onSubmit={handleSubmit}>
          <Form.Group>
            <Form.ControlLabel>Name *</Form.ControlLabel>
            <Form.Control
              placeholder='Name'
              name='name'
              onChange={(e) => setName(e)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>PLZ *</Form.ControlLabel>
            <TagInput
              trigger={["Enter", "Space", "Comma"]}
              placeholder='PLZ'
              style={{ width: 300 }}
              menuStyle={{ width: 300 }}
              defaultValue={[]}
              onChange={(e: any) => setZip(e)}
            />
          </Form.Group>

          <Form.Group>
            <ButtonToolbar>
              <Button
                appearance='primary'
                type='submit'
                disabled={zip.length <= 0 ? true : false}
              >
                Absenden
              </Button>
              <Button appearance='default' href='/districts'>
                Abbrechen
              </Button>
            </ButtonToolbar>
          </Form.Group>
        </Form>
      </Content>
    </div>
  );
};

export default CreateDistrict;
