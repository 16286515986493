import React, { useState } from "react";
import { Button, ButtonToolbar, Form } from "rsuite";
import Content from "../../components/content/content";
import { createDeliveryAddress } from "../../functions/deliveryAddresses";

import Root from "../../routes/navigation";

interface Props {
  withoutSidebar?: boolean;
}

const CreateDeliveryAddress = ({ withoutSidebar = false }: Props) => {
  const [deliveryAddressName, setDeliveryAddressName] = useState<string>();
  const [street, setStreet] = useState<string>("");
  const [streetNumber, setStreetNumber] = useState<string>();
  const [zip, setZip] = useState<string>("");
  const [city, setCity] = useState<string>("");

  const handleSubmit = async () => {
    const name = `${deliveryAddressName} - ${street} ${streetNumber}, ${zip} ${city}`;

    const body = {
      deliveryAddressName: deliveryAddressName!,
      name: name!,
      street: street!,
      streetNumber: streetNumber!,
      zip: zip!,
      city: city!,
    };
    await createDeliveryAddress(body);
    if (!withoutSidebar) {
      setTimeout(() => {
        window.location.pathname = "/deliveryAddresses";
      }, 500);
    }
  };

  return (
    <div className='DeliveryAddress'>
      {!withoutSidebar && <Root />}
      <Content
        style={{
          float: !withoutSidebar ? "right" : "left",
          width: !withoutSidebar ? "calc(100% - 300px)" : "100%",
        }}
      >
        {!withoutSidebar && <h1>Neue Herkunftsadresse anlegen</h1>}

        <Form layout='horizontal' onSubmit={handleSubmit}>
          <Form.Group>
            <Form.ControlLabel>Herkunftsname *</Form.ControlLabel>
            <Form.Control
              placeholder='Herkunftsname'
              name='deliveryAddressName'
              onChange={(e) => setDeliveryAddressName(e)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>Straße *</Form.ControlLabel>
            <Form.Control
              placeholder='Straße'
              name='street'
              onChange={(e) => setStreet(e)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Hausnummer *</Form.ControlLabel>
            <Form.Control
              placeholder='Hausnummer'
              name='streetNumber'
              onChange={(e) => setStreetNumber(e)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>PLZ *</Form.ControlLabel>
            <Form.Control
              placeholder='PLZ'
              name='zip'
              onChange={(e) => setZip(e)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Ort *</Form.ControlLabel>
            <Form.Control
              placeholder='Ort'
              name='city'
              onChange={(e) => setCity(e)}
              required
            />
          </Form.Group>

          <Form.Group>
            <ButtonToolbar>
              <Button appearance='primary' type='submit'>
                Absenden
              </Button>
              {!withoutSidebar && (
                <Button appearance='default' href='/deliveryAddresses'>
                  Abbrechen
                </Button>
              )}
            </ButtonToolbar>
          </Form.Group>
        </Form>
      </Content>
    </div>
  );
};

export default CreateDeliveryAddress;
