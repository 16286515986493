import React, { useState } from "react";
import { Button, ButtonToolbar, Form, Input, InputGroup } from "rsuite";
import FormControl from "rsuite/esm/FormControl";
import FormGroup from "rsuite/esm/FormGroup";
import Content from "../components/content/content";
import { createUser } from "../functions/users";

import Root from "../routes/navigation";

import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";

const CreateUser = () => {
  const [visible, setVisible] = useState(false);

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const handlePasswordVisibility = () => {
    setVisible(!visible);
  };

  const handleSubmit = async () => {
    const body = {
      name: name,
      password: password,
      email: email,
      isAdmin: isAdmin,
    };
    await createUser(body);
    setTimeout(() => {
      window.location.href = "/users";
    }, 500);
  };

  return (
    <div className='User'>
      <Root />
      <Content>
        <h1>Neuen Benutzer anlegen</h1>

        <Form layout='horizontal' onSubmit={() => handleSubmit()}>
          <FormGroup>
            <Form.ControlLabel>Name</Form.ControlLabel>
            <FormControl name='name' onChange={(e) => setName(e)} />
            <Form.HelpText>Vorname Nachname</Form.HelpText>
          </FormGroup>
          <FormGroup>
            <Form.ControlLabel>Email</Form.ControlLabel>
            <FormControl
              name='email'
              type='email'
              onChange={(e) => setEmail(e)}
            />
          </FormGroup>
          <FormGroup>
            <Form.ControlLabel>Passwort</Form.ControlLabel>
            <InputGroup inside>
              <Input
                type={visible ? "text" : "password"}
                onChange={(e) => setPassword(e)}
              />
              <InputGroup.Button onClick={handlePasswordVisibility}>
                {visible ? <EyeIcon /> : <EyeSlashIcon />}
              </InputGroup.Button>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Form.ControlLabel>Admin?</Form.ControlLabel>
            <input
              type='checkbox'
              onChange={(e: any) => {
                setIsAdmin(e.target.checked);
              }}
            />
          </FormGroup>
          <FormGroup>
            <ButtonToolbar style={{ justifyContent: "flex-end" }}>
              <Button appearance='primary' type='submit'>
                Benutzer anlegen
              </Button>
              <Button appearance='default' href='/users'>
                Abbrechen
              </Button>
            </ButtonToolbar>
          </FormGroup>
        </Form>
      </Content>
    </div>
  );
};

export default CreateUser;
