import React, { useState } from "react";
import { Button, ButtonToolbar, Form } from "rsuite";
import Content from "../../components/content/content";
import { createCustomer } from "../../functions/customers";

import Root from "../../routes/navigation";

const CreateCustomer = () => {
  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [locked, setLocked] = useState(false);

  const handleSubmit = async () => {
    const body = {
      name: name,
      street: street,
      streetNumber: streetNumber,
      city: city,
      zip: parseInt(zip),
      locked: locked,
    };
    await createCustomer(body);
    setTimeout(() => {
      window.location.href = "/customers";
    }, 500);
  };

  return (
    <div className='Customer'>
      <Root />
      <Content>
        <h1>Neuen Kunden anlegen</h1>

        <Form layout='horizontal' onSubmit={handleSubmit}>
          <Form.Group>
            <Form.ControlLabel>Name *</Form.ControlLabel>
            <Form.Control
              placeholder='Name'
              name='name'
              onChange={(e) => setName(e)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>Straße *</Form.ControlLabel>
            <Form.Control
              placeholder='Straße'
              name='street'
              onChange={(e) => setStreet(e)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>Hausnummer *</Form.ControlLabel>
            <Form.Control
              placeholder='Hausnummer'
              name='streetNumber'
              required
              onChange={(e) => setStreetNumber(e)}
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>PLZ *</Form.ControlLabel>
            <Form.Control
              placeholder='PLZ'
              name='zip'
              onChange={(e) => setZip(e)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>Ort *</Form.ControlLabel>
            <Form.Control
              placeholder='Ort'
              name='city'
              onChange={(e) => setCity(e)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>Gesperrt?</Form.ControlLabel>
            <input
              type='checkbox'
              onChange={(e: any) => {
                setLocked(e.target.checked);
              }}
            />
          </Form.Group>

          <Form.Group>
            <ButtonToolbar>
              <Button appearance='primary' type='submit'>
                Absenden
              </Button>
              <Button appearance='default' href='/customers'>
                Abbrechen
              </Button>
            </ButtonToolbar>
          </Form.Group>
        </Form>
      </Content>
    </div>
  );
};

export default CreateCustomer;
