import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Button, Table, Pagination } from "rsuite";
import Content from "../../components/content/content";
import Picker from "../../components/Picker/Picker";
import { Search } from "../../components/Search/Search";
import ActionCell from "../../components/Table/ActionCell";
import EditableCell from "../../components/Table/EditableCell";
import { getAllAnalyses, updateAnalyseById } from "../../functions/analyses";
import { getAllArticles } from "../../functions/articles";
import { getAllCustomers } from "../../functions/customers";
import {
  deleteDelivery,
  getAllDeliveries,
  getDeliveriesByAnalyse,
  updateDelivery,
} from "../../functions/deliveries";
import { getAllDeliveryAddresses } from "../../functions/deliveryAddresses";
import { getAllDistricts } from "../../functions/districts";
import { getAllUsers } from "../../functions/users";
import Analyse from "../../interfaces/analyse";
import Article from "../../interfaces/article";
import Customer from "../../interfaces/customer";
import Delivery from "../../interfaces/delivery";
import DeliveryAddress from "../../interfaces/deliveryAddress";
import District from "../../interfaces/district";
import PaginatedResult from "../../interfaces/paginatedResult";
import User from "../../interfaces/user";

import Root from "../../routes/navigation";

import { Loader } from "rsuite";

import { DateRangePicker } from "rsuite";

import "./deliveries.scss";

const Deliveries = () => {
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const { Column, HeaderCell, Cell } = Table;
  const [paginatedResult, setPaginatedResult] = useState<
    PaginatedResult<Delivery>
  >();
  const [customerFilter, setCustomerFilter] = useState<number>();
  const [deliveries, setDeliveries] = useState<Delivery[]>([]);
  const [query, setQuery] = useState("");
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [deliveryAddresses, setDeliveryAddresses] = useState<DeliveryAddress[]>(
    []
  );
  const [dateRange, setDateRange] = useState<Array<string>>([]);
  const [districts, setDistricts] = useState<District[]>([]);
  const [analyses, setAnalyses] = useState<Analyse[]>([]);
  const [articles, setArticles] = useState<Article[]>([]);

  const [sortColumn, setSortColumn] = React.useState<any>();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState<boolean>(true);

  const handleChange = (id: number, key: number, value: any) => {
    const containsOnlyNumbers = !Array.isArray(value) && /^[0-9]+$/.test(value);

    value = containsOnlyNumbers ? parseInt(value) : value;

    const nextData = Object.assign([], deliveries);
    //@ts-ignore
    nextData.find((item) => item.id === id)[key] = value;

    //@ts-ignore
    const updatedItem: Delivery = nextData.find((item) => item.id! === id);

    const updatedAddress: DeliveryAddress = deliveryAddresses.find(
      (deliveryAddress) =>
        deliveryAddress.id === updatedItem!.deliveryAddressId!
    )!;
    console.log("updatedAddress", updatedAddress);
    console.log("districts", districts);
    const updatedDistrict: District = districts.find((district) => {
      return district.zip.includes(updatedAddress.zip.toString());
    })!;
    console.log("updatedDistrict", updatedDistrict);

    //@ts-ignore
    updatedItem.districtId = updatedDistrict.id;

    setDeliveries(nextData);
    getDeliveryValues();
  };

  const handleEditState = async (id: number) => {
    const nextData = Object.assign([], deliveries);
    //@ts-ignore
    const activeItem = nextData.find((item) => item.id === id);
    //@ts-ignore
    activeItem.status = activeItem.status ? null : "EDIT";
    setDeliveries(nextData);
    //@ts-ignore
    if (!activeItem.status) {
      await handleUpdate(id);
      //@ts-ignore
      await updateAnalyse(parseInt(activeItem!.analyseId!));
    }
  };

  const updateAnalyse = async (analyseId: number) => {
    await getDeliveriesByAnalyse(analyseId).then((res) => {
      let currentWeight = "0";

      res.map((delivery: Delivery) => {
        currentWeight = (
          parseFloat(currentWeight) + parseFloat(delivery.netWeight)
        ).toString();
        return currentWeight;
      });

      updateAnalyseById(analyseId, {
        deliveredQuantity: parseFloat(currentWeight.toString()),
      });
    });
  };

  const handleDeleteState = async (id: number) => {
    const nextData = Object.assign([], deliveries);
    //@ts-ignore
    const activeItem = nextData.find((item) => item.id === id);

    nextData.splice(nextData.indexOf(activeItem!), 1);

    setDeliveries(nextData);

    await deleteDelivery(id);
  };

  useEffect(() => {
    getAllCustomers(1, 99999)
      .then((data: PaginatedResult<Customer>) => {
        setCustomers(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });

    getAllDeliveryAddresses(1, 99999)
      .then((data) => {
        setDeliveryAddresses(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });

    getAllDistricts(1, 99999)
      .then((data) => {
        setDistricts(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });

    getAllUsers(1, 99999)
      .then((data) => {
        setUsers(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });

    getAllAnalyses(1, 99999)
      .then((data: PaginatedResult<Analyse>) => {
        setAnalyses(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });

    getAllArticles(1, 99999)
      .then((data: PaginatedResult<Article>) => {
        setArticles(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const allDeliveries = getAllDeliveries(
      page,
      limit,
      query,
      customerFilter,
      sortType,
      sortColumn,
      dateRange
    );
    if (allDeliveries) {
      allDeliveries
        .then((data: PaginatedResult<Delivery>) => {
          setPaginatedResult(data);
          setDeliveries(data.items);

          data.items.map((item: Delivery) => {
            // @ts-ignore
            const articles = JSON.parse(item.articles);
            const articlesArray = [];
            articles.map((item: number) => {
              articlesArray.push(item);
              return item;
            });
            item.articles = articles;
            return item;
          });
          return data;
        })
        .catch((error) => {
          console.error(error);
        });
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [page, limit, query, customerFilter, sortColumn, sortType, dateRange]);

  const handleUpdate = async (id: number) => {
    const deliveryToUpdate = deliveries.find((delivery) => delivery.id === id);
    const body = {
      customerId: deliveryToUpdate!.customerId,
      deliveryAddressId: deliveryToUpdate!.deliveryAddressId,
      districtId: deliveryToUpdate!.districtId,
      lNumber: deliveryToUpdate!.lNumber ? deliveryToUpdate!.lNumber : null,
      articles: deliveryToUpdate!.articles,
      analyseId: deliveryToUpdate!.analyseId,
      netWeight: deliveryToUpdate!.netWeight.toString().includes(",")
        ? parseFloat(deliveryToUpdate!.netWeight.toString().replace(",", "."))
        : parseFloat(deliveryToUpdate!.netWeight.toString()),
      weighingTicketNumber: deliveryToUpdate!.weighingTicketNumber,
      date: deliveryToUpdate!.date,
      userId: deliveryToUpdate!.userId,
      carrier: deliveryToUpdate!.carrier,
      createdBy: deliveryToUpdate!.createdBy,
    };
    const updatedDelivery = await updateDelivery(id, body);
  };

  const getSortedData = () => {
    getDeliveryValues();
    return deliveries;
  };

  const getDeliveryValues = () => {
    deliveries.map((delivery) => {
      if (delivery.netWeight && typeof delivery.netWeight === "number") {
        const netWeightNumber = parseFloat(delivery.netWeight)
          .toFixed(2)
          .replace(".", ",");

        delivery.netWeight = netWeightNumber;
      }
      Object.assign(delivery, {
        formattedDate: format(new Date(delivery.date), "dd.MM.yyyy"),
      });
      customers.find((customer) => {
        if (customer.id === delivery.customerId) {
          if (delivery.customer) {
            delivery[
              "customer"
            ] = `${customer.name} - ${customer.street} ${customer.streetNumber}, ${customer.zip} ${customer.city}`;
          } else {
            Object.assign(delivery, {
              customer: `${customer.name} - ${customer.street} ${customer.streetNumber}, ${customer.zip} ${customer.city}`,
            });
          }
        }
      });
      deliveryAddresses.find((deliveryAddress) => {
        if (deliveryAddress.id === delivery.deliveryAddressId) {
          if (delivery.deliveryAddress)
            delivery["deliveryAddress"] = deliveryAddress.name;
          else
            Object.assign(delivery, {
              deliveryAddress: deliveryAddress.name,
            });
        }
      });
      districts.find((district) => {
        if (district.id === delivery.districtId) {
          if (delivery.district) {
            delivery["district"] = district.name;
          } else {
            Object.assign(delivery, { district: district.name });
          }
        }
      });
      analyses.find((analyse) => {
        if (analyse.id === delivery.analyseId) {
          if (delivery.analyse) {
            delivery["analyse"] = analyse.analyseNumber;
          } else {
            Object.assign(delivery, { analyse: analyse.analyseNumber });
          }
        }
      });
      users.find((user) => {
        if (user.id === delivery.userId) {
          if (delivery.user) {
            delivery["user"] = user.name;
          } else {
            Object.assign(delivery, { user: user.name });
          }
        }
        if (user.id === delivery.createdBy) {
          if (delivery.createdByUser) {
            delivery["createdByUser"] = user.name;
          } else {
            Object.assign(delivery, { createdByUser: user.name });
          }
        }
      });
      const articleName: string[] = [];
      if (delivery.articles && Array.isArray(delivery.articles)) {
        delivery.articles.map((i) => {
          articles.find((article) => {
            //@ts-ignore
            if (article.id === i) {
              articleName.push(article.name);
            }
          });
        });
        Object.assign(delivery, { article: articleName.join(", ") });
      } else {
        articles.find((article) => {
          //@ts-ignore
          if (article.id === delivery.articles) {
            Object.assign(delivery, { article: article.name });
          }
        });
      }
    });
  };

  const handleSearchQuery = async (value: string) => {
    setQuery(value);
    setPage(1);
  };

  const handleRangeQuery = async (value: Array<Date>) => {
    console.log("value", value);
    if (value) {
      value[0] = new Date(value[0].setHours(1, 0, 0, 0));
      value[1] = new Date(value[1].setHours(24, 59, 59, 59));
      const val = [value[0].toISOString(), value[1].toISOString()];
      setDateRange(val);
    } else {
      setDateRange([]);
    }
    setPage(1);
  };

  //@ts-ignore
  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      if (sortColumn === "customer") {
        const sort = "customerId";
        setSortColumn(sort);
        setSortType(sortType);
      } else {
        setSortColumn(sortColumn);
        setSortType(sortType);
      }
    }, 500);
  };

  const headers = [
    { label: "Id", key: "id" },
    { label: "Kunde", key: "customer" },
    { label: "Herkunftsadresse", key: "deliveryAddress" },
    { label: "Bezirk", key: "district" },
    { label: "L-Nummer", key: "lNumber" },
    { label: "Artikel", key: "article" },
    { label: "Analyse", key: "analyse" },
    { label: "Netto-Gewicht", key: "netWeight" },
    { label: "Wiegeticketnummer", key: "weighingTicketNumber" },
    { label: "Datum", key: "formattedDate" },
    { label: "Spediteur", key: "carrier" },
    { label: "Erstellt von", key: "createdByUser" },
  ];

  const csvReport = {
    data: deliveries,
    headers: headers,
    filename: "Deliveries_Export.csv",
  };

  const handleChangeLimit = (dataKey: any) => {
    setPage(1);
    setLimit(dataKey);
  };

  if (!paginatedResult) {
    return (
      <>
        <Root />
        <Content>
          <Loader content='wird geladen...' />
        </Content>
      </>
    );
  } else {
    return (
      <div className='Delivery'>
        <Root />
        <Content>
          <h1>Lieferungen</h1>
          <div
            className='actions'
            style={{ marginBottom: 20, display: "flex", gridGap: 20 }}
          >
            <Button href='/deliveries/create'>Neue Lieferung anlegen</Button>
            <Search
              onChange={(e) => handleSearchQuery(e)}
              placeholder='Nach Wiegeticketnummer suchen'
            />
            <Picker
              data={customers.filter((item) => item.locked !== true)! as []}
              defaultValue={[]}
              onChange={(e) => {
                setCustomerFilter(e);
                setPage(1);
              }}
              placeholder='Nach Kunde filtern'
            />
            <DateRangePicker
              character=' – '
              onChange={(e: any) => handleRangeQuery(e)}
              format='dd.MM.yyyy'
            />
          </div>
          <div style={{ position: "relative" }}>
            <Table
              virtualized
              data={getSortedData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              cellBordered
              autoHeight
            >
              <Column align='center' width={70} sortable>
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey='id' />
              </Column>
              <Column align='center' width={200} resizable>
                <HeaderCell>Kunde</HeaderCell>
                <EditableCell
                  dataKey='customer'
                  datakeyedit='customerId'
                  usepicker={true}
                  pickerdata={customers}
                  onChange={handleChange}
                />
              </Column>
              <Column align='center' width={200} resizable>
                <HeaderCell>Herkunftsadresse</HeaderCell>
                {/* <EditableCell
                  dataKey='deliveryAddress'
                  datakeyedit='deliveryAddressId'
                  usepicker={true}
                  pickerdata={deliveryAddresses}
                  onChange={handleChange}
                /> */}
                <Cell dataKey='deliveryAddress' />
              </Column>
              <Column align='center' width={150}>
                <HeaderCell>Bezirk</HeaderCell>
                <Cell dataKey='district' />
              </Column>
              <Column align='center' width={120}>
                <HeaderCell>Wiegeticketnummer</HeaderCell>
                <EditableCell
                  dataKey='weighingTicketNumber'
                  onChange={handleChange}
                />
              </Column>

              <Column align='center' width={150} resizable>
                <HeaderCell>Artikel</HeaderCell>
                {/* <EditableCell
                  dataKey='article'
                  datakeyedit='articles'
                  usepicker={true}
                  pickerdata={articles}
                  multiple={true}
                  onChange={handleChange}
                /> */}
                <Cell dataKey='article' />
              </Column>
              <Column align='center' width={150}>
                <HeaderCell>Analyse</HeaderCell>
                {/* <EditableCell
                  dataKey='analyse'
                  datakeyedit='analyseId'
                  usepicker={true}
                  pickerdata={analyses}
                  onChange={handleChange}
                /> */}
                <Cell dataKey='analyse' />
              </Column>
              <Column align='center' width={120}>
                <HeaderCell>Netto-Gewicht</HeaderCell>
                <EditableCell dataKey='netWeight' onChange={handleChange} />
              </Column>
              <Column align='center' width={120}>
                <HeaderCell>L-Nummer</HeaderCell>
                <EditableCell dataKey='lNumber' onChange={handleChange} />
              </Column>
              <Column align='center' width={150}>
                <HeaderCell>Datum</HeaderCell>
                <EditableCell
                  dataKey='formattedDate'
                  datakeyedit='date'
                  datepicker={true}
                  onChange={handleChange}
                />
              </Column>
              <Column align='center' width={150}>
                <HeaderCell>Spediteur</HeaderCell>
                <EditableCell dataKey='carrier' onChange={handleChange} />
              </Column>
              <Column align='center' width={150}>
                <HeaderCell>Erstellt von</HeaderCell>
                <Cell dataKey='createdByUser' />
              </Column>
              <Column width={200} fixed='right'>
                <HeaderCell>Aktionen</HeaderCell>
                <ActionCell
                  dataKey='id'
                  onClick={handleEditState}
                  onDelete={handleDeleteState}
                />
              </Column>
            </Table>
            {loading && <Loader content='wird geladen...' />}
          </div>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size='xs'
              layout={["total", "-", "limit", "|", "pager"]}
              total={paginatedResult!.totalItems}
              limitOptions={[10, 30, 50, 9999]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
          <CSVLink style={{ marginTop: 8 }} {...csvReport}>
            Ansicht als CSV herunterladen
          </CSVLink>
        </Content>
      </div>
    );
  }
};

export default Deliveries;
