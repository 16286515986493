import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  DatePicker,
  Form,
  Message,
  Modal,
} from "rsuite";
import Content from "../../components/content/content";
import Picker from "../../components/Picker/Picker";
import { getAllAnalyses, updateAnalyseById } from "../../functions/analyses";
import { getAllArticles } from "../../functions/articles";
import { getAllCustomers } from "../../functions/customers";
import {
  createDelivery,
  getDeliveriesByAnalyse,
} from "../../functions/deliveries";
import { getAllDeliveryAddresses } from "../../functions/deliveryAddresses";
import { getAllDistricts } from "../../functions/districts";
import Analyse from "../../interfaces/analyse";
import Article from "../../interfaces/article";
import Customer from "../../interfaces/customer";
import Delivery from "../../interfaces/delivery";
import DeliveryAddress from "../../interfaces/deliveryAddress";
import District from "../../interfaces/district";

import Root from "../../routes/navigation";
import CreateDeliveryAddress from "../deliveryAddresses/createDeliveryAddress";

const CreateDelivery = () => {
  const [customerId, setCustomerId] = useState<number>();
  const [deliveryAddressId, setDeliveryAddressId] = useState<number>();
  const [lNumber, setLNumber] = useState<string>("");
  const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);
  const [analyseId, setAnalyseId] = useState<string>("");
  const [netWeight, setNetWeight] = useState<string>("");
  const [weighingTicketNumber, setWeighingTicketNumber] = useState<string>("");
  const [date, setDate] = useState<Date>(new Date(Date.now()));
  const [carrier, setCarrier] = useState<string>("");
  const [analyses, setAnalyses] = useState<Analyse[]>([]);

  const [analyseWeight, setAnalyseWeight] = useState<number>();

  const [loading, setLoading] = useState(true);

  const [articles, setArticles] = useState<Article[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [deliveryAddresses, setDeliveryAddresses] = useState<DeliveryAddress[]>(
    []
  );
  const [districts, setDistricts] = useState<District[]>([]);

  const getDistrictValues = () => {
    const deliveryAddress = deliveryAddresses.find(
      (deliveryAddress) => deliveryAddress.id === deliveryAddressId
    )!;
    const deliveryDistrict: District = districts.find((district) => {
      return district.zip.includes(deliveryAddress.zip.toString());
    })!;
    return deliveryDistrict;
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    const deliveryDistrict = getDistrictValues();

    const body = {
      customerId: customerId!,
      deliveryAddressId: deliveryAddressId!,
      districtId: deliveryDistrict.id,
      lNumber: parseInt(lNumber!),
      articles: selectedArticles,
      analyseId: parseInt(analyseId!),
      netWeight: parseFloat(netWeight!.replace(",", ".")),
      weighingTicketNumber: parseInt(weighingTicketNumber!),
      date: date!,
      carrier: carrier!,
      createdBy: currentUser,
    };
    await createDelivery(body);
    await updateAnalyse(parseInt(analyseId));
    setTimeout(() => {
      window.location.href = "/deliveries";
    }, 500);
    return;
  };

  const updateAnalyse = async (analyseId: number) => {
    await getDeliveriesByAnalyse(analyseId).then((res) => {
      let currentWeight = 0;

      res.map((delivery: Delivery) => {
        return (currentWeight += parseFloat(delivery.netWeight));
      });

      console.log(currentWeight);

      updateAnalyseById(analyseId, {
        deliveredQuantity: parseFloat(currentWeight.toString()),
      });
    });
  };

  const currentUser: number = parseInt(localStorage.getItem("user")!) || 0;

  useEffect(() => {
    getAllArticles(1, 99999)
      .then((data) => {
        setArticles(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
    getAllCustomers(1, 99999)
      .then((data) => {
        setCustomers(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });

    getAllDeliveryAddresses(1, 99999)
      .then((data) => {
        setDeliveryAddresses(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });

    getAllDistricts(1, 99999)
      .then((data) => {
        setDistricts(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });

    getAllAnalyses(1, 99999)
      .then((data) => {
        setAnalyses(data.items);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });

    setLoading(false);
  }, [open, analyseId]);

  const getAnalyseWeight = (e: any) => {
    setAnalyseId(e);

    const currentAnalyse = analyses.find((item) => item.id === parseInt(e))!;

    setDeliveryAddressId(currentAnalyse.deliveryAddressId);

    const newArticles = JSON.parse(currentAnalyse.articles as any);

    newArticles.map((item: Article) => selectedArticles.push(item));

    const max = analyses.find((analyse) => analyse.id === parseInt(e))
      ?.maxQuantity!;
    const current = analyses.find((analyse) => analyse.id === parseInt(e))
      ?.deliveredQuantity!;

    setAnalyseWeight(parseFloat(max) - parseFloat(current));

    return (
      parseFloat(max) - parseFloat(current) &&
      setDeliveryAddressId(currentAnalyse!.deliveryAddressId)
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className='Delivery'>
        <Root />
        <Content>
          <h1>Neue Lieferung anlegen</h1>

          <Form layout='horizontal' onSubmit={() => handleSubmit()}>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Form.Group>
                  <Form.ControlLabel>Kunde *</Form.ControlLabel>
                  <Picker
                    placeholder='Kunde'
                    data={customers! as []}
                    defaultValue={""}
                    onChange={(e) => {
                      setCustomerId(e);
                    }}
                    style={{ width: "300px" }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Wiegeticketnummer *</Form.ControlLabel>
                  <Form.Control
                    placeholder='Wiegeticketnummer'
                    name='weighingTicketNumber'
                    onChange={(e) => setWeighingTicketNumber(e)}
                    required
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>L-Nummer</Form.ControlLabel>
                  <Form.Control
                    placeholder='L-Nummer'
                    name='lNumber'
                    onChange={(e) => setLNumber(e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Netto Gewicht *</Form.ControlLabel>
                  <Form.Control
                    placeholder='Netto Gewicht'
                    name='netWeight'
                    className={
                      parseInt(netWeight) > analyseWeight! ? "error" : ""
                    }
                    onChange={(e) => setNetWeight(e)}
                    required
                  />
                </Form.Group>

                {/* <Form.Group>
                  <Form.ControlLabel>Lieferadresse *</Form.ControlLabel>
                  <Picker
                    placeholder='Lieferadresse'
                    data={deliveryAddresses! as []}
                    defaultValue={""}
                    onChange={(e) => {
                      setDeliveryAddressId(e);
                    }}
                    style={{ width: "300px" }}
                  />
                  <br />
                  <a
                    onClick={handleOpen}
                    style={{
                      fontSize: "12px",
                      marginTop: "5px",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                  >
                    Neue Herkunftsadresse anlegen
                  </a>
                </Form.Group> */}

                {/* <Form.Group>
                  <Form.ControlLabel>Artikel *</Form.ControlLabel>
                  <Picker
                    placeholder='Artikel'
                    data={articles as []}
                    defaultValue={[]}
                    multiple={true}
                    onChange={(e) => {
                      setSelectedArticles(e);
                    }}
                    style={{ width: "300px" }}
                  />
                </Form.Group> */}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Form.Group style={{ position: "relative" }}>
                  <Form.ControlLabel>Analyse *</Form.ControlLabel>
                  <Picker
                    placeholder='Analyse'
                    data={analyses as []}
                    defaultValue={[]}
                    onChange={(e) =>
                      //setAnalyseId(e);
                      getAnalyseWeight(e)
                    }
                    style={{ width: "300px" }}
                  />
                  {analyseId && analyseWeight && (
                    <>
                      {analyseWeight >= 1 && (
                        <Message
                          type='info'
                          style={{
                            left: "480px",
                            top: "0",
                            minWidth: "230px",
                            position: "absolute",
                          }}
                        >
                          Noch maximal {analyseWeight}t lieferbar.
                        </Message>
                      )}
                      {analyseWeight <= 0 && (
                        <Message
                          type='error'
                          style={{
                            left: "480px",
                            top: "0",
                            minWidth: "350px",
                            position: "absolute",
                          }}
                        >
                          Diese Analyse kann nicht mehr beliefert werden.
                        </Message>
                      )}
                    </>
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Datum *</Form.ControlLabel>
                  <DatePicker
                    placeholder='Datum'
                    name='date'
                    defaultValue={date}
                    onChange={(e) => (e !== null ? setDate(e) : undefined)}
                    format='dd.MM.yyyy'
                    style={{ width: "300px" }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Spediteur *</Form.ControlLabel>
                  <Form.Control
                    placeholder='Spediteur'
                    name='carrier'
                    onChange={(e) => setCarrier(e)}
                    required
                  />
                </Form.Group>

                <Form.Group>
                  <ButtonToolbar>
                    <Button
                      appearance='primary'
                      type='submit'
                      disabled={
                        parseInt(netWeight) > analyseWeight! ||
                        !articles ||
                        !analyseId ||
                        !date
                      }
                    >
                      Absenden
                    </Button>

                    <Button appearance='default' href='/deliveries'>
                      Abbrechen
                    </Button>
                  </ButtonToolbar>
                </Form.Group>

                <Modal open={open} onClose={handleClose}>
                  <Modal.Header>
                    <Modal.Title>Neue Lieferadresse anlegen</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <CreateDeliveryAddress withoutSidebar={true} />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleClose} appearance='subtle'>
                      Dialog schließen
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </Form>
        </Content>
      </div>
    );
  }
};

export default CreateDelivery;
