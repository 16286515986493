import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Button, Loader, Pagination, Table } from "rsuite";
import Content from "../components/content/content";
import { Search } from "../components/Search/Search";
import ActionCell from "../components/Table/ActionCell";
import EditableCell from "../components/Table/EditableCell";
import { deleteUser, getAllUsers, updateUser } from "../functions/users";
import PaginatedResult from "../interfaces/paginatedResult";
import User from "../interfaces/user";
import Root from "../routes/navigation";

import "./users.scss";

const Users = () => {
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const { Column, HeaderCell, Cell } = Table;
  const [paginatedResult, setPaginatedResult] = useState<
    PaginatedResult<User>
  >();

  const [users, setUsers] = useState<User[]>([]);

  const [query, setQuery] = useState("");

  const [sortColumn, setSortColumn] = React.useState<any>();
  const [sortType, setSortType] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);

  const [currentUser, setCurrentUser] = React.useState<User>();

  const handleChange = (id: number, key: any, value: any) => {
    const nextData = Object.assign([], users);
    //@ts-ignore
    nextData.find((item) => item.id === id)[key] = value;

    if (key === "isAdmin") {
      //@ts-ignore
      nextData.find((item) => item.id === id)["isAdminText"] = value.toString();
    }

    setUsers(nextData);
  };

  const handleEditState = (id: number) => {
    const nextData = Object.assign([], users);
    //@ts-ignore
    const activeItem = nextData.find((item) => item.id === id);
    //@ts-ignore
    activeItem.status = activeItem.status ? null : "EDIT";
    setUsers(nextData);
    //@ts-ignore
    if (!activeItem.status) {
      handleUpdate(id);
    }
  };

  useEffect(() => {
    setLoading(true);
    const allUsers = getAllUsers(page, limit, query, sortType, sortColumn);
    if (allUsers) {
      allUsers
        .then((data: PaginatedResult<User>) => {
          setPaginatedResult(data);

          data.items.map((user: User) => {
            Object.assign(user, {
              isAdminText: user.isAdmin.toString(),
            });
          });

          setUsers(data.items);
          const ownUser = data.items.find(
            (user) => user.id === parseInt(localStorage.getItem("user")!)
          );
          setCurrentUser(ownUser);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [page, limit, query, sortColumn, sortType]);

  const handleUpdate = async (id: number) => {
    const userToUpdate = users.find((user) => user.id === id);
    const body = {
      email: userToUpdate!.email,
      name: userToUpdate!.name,
      isAdmin: userToUpdate!.isAdmin,
    };
    updateUser(id, body);
  };

  const handleDeleteState = async (id: number) => {
    const nextData = Object.assign([], users);
    //@ts-ignore
    const activeItem = nextData.find((item) => item.id === id);

    nextData.splice(nextData.indexOf(activeItem!), 1);

    setUsers(nextData);

    await deleteUser(id);
  };

  const getSortedData = () => {
    return users;
  };

  const handleSearchQuery = async (value: string) => {
    setQuery(value);
    setPage(1);
  };

  //@ts-ignore
  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const headers = [
    { label: "Id", key: "id" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "ist Admin", key: "isAdmin" },
  ];

  const csvReport = {
    data: users,
    headers: headers,
    filename: "Users_Export.csv",
  };

  const handleChangeLimit = (dataKey: any) => {
    setPage(1);
    setLimit(dataKey);
  };

  if (!paginatedResult) {
    return (
      <>
        <Root />
        <Content>
          <Loader content='wird geladen...' />
        </Content>
      </>
    );
  } else {
    return (
      <div className='User'>
        <Root />
        <Content>
          <h1>Benutzer</h1>
          <div style={{ marginBottom: 20, display: "flex", gridGap: 20 }}>
            {currentUser && currentUser.isAdmin === true && (
              <Button href='/users/create'>Neuen Benutzer anlegen</Button>
            )}
            <Search
              onChange={(e) => handleSearchQuery(e)}
              placeholder='Nach Namen suchen'
            />
          </div>
          <div style={{ position: "relative" }}>
            <Table
              virtualized
              data={getSortedData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              cellBordered
              loading={loading}
              autoHeight
            >
              <Column align='center' width={70} sortable>
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey='id' />
              </Column>
              <Column align='center' width={300} sortable>
                <HeaderCell>Name</HeaderCell>
                <EditableCell dataKey='name' onChange={handleChange} />
              </Column>
              <Column align='center' width={300}>
                <HeaderCell>Email</HeaderCell>
                <EditableCell dataKey='email' onChange={handleChange} />
              </Column>
              <Column align='center' width={70}>
                <HeaderCell>isAdmin</HeaderCell>
                <EditableCell
                  dataKey='isAdminText'
                  datakeyedit='isAdmin'
                  onChange={handleChange}
                  usecheckbox={true}
                />
              </Column>

              {currentUser && currentUser.isAdmin === true && (
                <Column width={200}>
                  <HeaderCell>Aktionen</HeaderCell>
                  <ActionCell
                    dataKey='id'
                    onClick={handleEditState}
                    onDelete={handleDeleteState}
                  />
                </Column>
              )}
              {currentUser && currentUser.isAdmin === false && (
                <Column width={100}>
                  <HeaderCell>Aktionen</HeaderCell>
                  <ActionCell dataKey='id' onClick={handleEditState} />
                </Column>
              )}
            </Table>
            {loading && <Loader content='wird geladen...' />}
          </div>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size='xs'
              layout={["total", "-", "limit", "|", "pager"]}
              total={paginatedResult!.totalItems}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
          <CSVLink style={{ marginTop: 8 }} {...csvReport}>
            Ansicht als CSV herunterladen
          </CSVLink>
        </Content>
      </div>
    );
  }
};

export default Users;
