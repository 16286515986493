import React, { useState } from "react";
import { Button, ButtonToolbar, Form } from "rsuite";
import Content from "../../components/content/content";
import { createArticle } from "../../functions/articles";

import Root from "../../routes/navigation";

const CreateArticle = () => {
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");

  const handleSubmit = async () => {
    const body = {
      name: name,
      shortName: shortName,
    };
    await createArticle(body);
    setTimeout(() => {
      window.location.pathname = "/articles";
    }, 500);
  };

  return (
    <div className='Article'>
      <Root />
      <Content>
        <h1>Neuen Artikel anlegen</h1>

        <Form layout='horizontal' onSubmit={handleSubmit}>
          <Form.Group>
            <Form.ControlLabel>Name *</Form.ControlLabel>
            <Form.Control
              placeholder='Name'
              name='name'
              onChange={(e) => setName(e)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>Kurzbezeichnung *</Form.ControlLabel>
            <Form.Control
              placeholder='Kurzbezeichnung'
              name='street'
              onChange={(e) => setShortName(e)}
              required
            />
          </Form.Group>

          <Form.Group>
            <ButtonToolbar>
              <Button appearance='primary' type='submit'>
                Absenden
              </Button>
              <Button appearance='default' href='/articles'>
                Abbrechen
              </Button>
            </ButtonToolbar>
          </Form.Group>
        </Form>
      </Content>
    </div>
  );
};

export default CreateArticle;
