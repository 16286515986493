import React, { useState } from "react";
import { Nav, Sidenav } from "rsuite";
import PeoplesIcon from "@rsuite/icons/Peoples";
import BranchIcon from "@rsuite/icons/Branch";
import EventDetailIcon from "@rsuite/icons/EventDetail";
import ListIcon from "@rsuite/icons/List";
import LocationIcon from "@rsuite/icons/Location";
import OffIcon from "@rsuite/icons/Off";

import "./navigation.scss";
import { signOut } from "../functions/auth";

// import env from "react-dotenv";

export default function Root() {
  const pathname = window.location.pathname.split("/");

  const active = pathname[1];
  const [expanded, setExpanded] = useState(true);

  // (window as any).apiURL = env.REACT_APP_API_URL;

  return (
    <div>
      <Sidenav expanded={expanded} id='sidebar'>
        <div style={{ padding: "0 20px", textAlign: "center" }}>
          <img src='/logo.svg' alt='logo' style={{ maxWidth: "100%" }} />
        </div>
        <Sidenav.Body style={{ height: "100%" }}>
          <Nav vertical activeKey={active} style={{ width: "100%" }}>
            <Nav.Item
              eventKey='analyses'
              href='/analyses'
              icon={<EventDetailIcon />}
            >
              Analyse
            </Nav.Item>
            <Nav.Item
              eventKey='deliveries'
              href='/deliveries'
              icon={<LocationIcon />}
            >
              Lieferungen
            </Nav.Item>

            <hr style={{ opacity: 0.2 }} />

            <Nav.Item
              eventKey='customers'
              href='/customers'
              icon={<BranchIcon />}
            >
              Kunden
            </Nav.Item>
            <Nav.Item eventKey='articles' href='/articles' icon={<ListIcon />}>
              Artikel
            </Nav.Item>
            <Nav.Item
              eventKey='districts'
              href='/districts'
              icon={<LocationIcon />}
            >
              Bezirke
            </Nav.Item>
            <Nav.Item
              eventKey='deliveryAddresses'
              href='/deliveryAddresses'
              icon={<LocationIcon />}
            >
              Herkunftsadressen
            </Nav.Item>

            <hr style={{ opacity: 0.2 }} />

            <Nav.Item eventKey='users' href='/users' icon={<PeoplesIcon />}>
              Benutzer
            </Nav.Item>

            <hr style={{ opacity: 0.2 }} />

            <Nav.Item
              eventKey='logout'
              href='/'
              onClick={() => signOut()}
              icon={<OffIcon />}
            >
              Ausloggen
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
        <Sidenav.Toggle onToggle={(expanded) => setExpanded(expanded)} />
      </Sidenav>
    </div>
  );
}
