import React, { useEffect, useState } from "react";
import { Button, Loader, Pagination, Table } from "rsuite";
import Content from "../../components/content/content";
import { Search } from "../../components/Search/Search";
import ActionCell from "../../components/Table/ActionCell";
import EditableCell from "../../components/Table/EditableCell";
import {
  deleteCustomer,
  getAllCustomers,
  updateCustomer,
} from "../../functions/customers";
import Customer from "../../interfaces/customer";
import Root from "../../routes/navigation";

import { CSVLink } from "react-csv";
import "./customers.scss";
import PaginatedResult from "../../interfaces/paginatedResult";

const Customers = () => {
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const { Column, HeaderCell, Cell } = Table;
  const [paginatedResult, setPaginatedResult] = useState<
    PaginatedResult<Customer>
  >();

  const [customers, setCustomers] = useState<Customer[]>([]);

  const [query, setQuery] = useState("");
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleChange = (id: number, key: any, value: any) => {
    const nextData = Object.assign([], customers);
    //@ts-ignore
    nextData.find((item) => item.id === id)[key] = value;

    if (key === "locked") {
      //@ts-ignore
      nextData.find((item) => item.id === id)[
        "isLockedText"
      ] = value.toString();
    }

    setCustomers(nextData);
  };

  const handleEditState = (id: number) => {
    const nextData = Object.assign([], customers);
    //@ts-ignore
    const activeItem = nextData.find((item) => item.id === id);
    //@ts-ignore
    activeItem.status = activeItem.status ? null : "EDIT";
    setCustomers(nextData);
    //@ts-ignore
    if (!activeItem.status) {
      handleUpdate(id);
    }
  };

  useEffect(() => {
    setLoading(true);
    const allDeliveries = getAllCustomers(
      page,
      limit,
      query,
      sortType,
      sortColumn
    );
    if (allDeliveries) {
      allDeliveries
        .then((data: PaginatedResult<Customer>) => {
          setPaginatedResult(data);
          data.items.map((customer: Customer) => {
            Object.assign(customer, {
              isLockedText: customer.locked.toString(),
            });
          });
          setCustomers(data.items);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [page, limit, query, sortColumn, sortType]);

  const handleUpdate = async (id: number) => {
    const customerToUpdate = customers.find((customer) => customer.id === id);
    const body = {
      name: customerToUpdate!.name,
      street: customerToUpdate!.street,
      streetNumber: customerToUpdate!.streetNumber,
      zip: customerToUpdate!.zip,
      city: customerToUpdate!.city,
      locked: customerToUpdate!.locked,
    };
    updateCustomer(id, body);
  };

  const handleDeleteState = async (id: number) => {
    const nextData = Object.assign([], customers);
    //@ts-ignore
    const activeItem = nextData.find((item) => item.id === id);

    nextData.splice(nextData.indexOf(activeItem!), 1);

    setCustomers(nextData);

    await deleteCustomer(id);
  };

  const getSortedData = () => {
    return customers;
  };

  const handleSearchQuery = async (value: string) => {
    setQuery(value);
    setPage(1);
  };

  //@ts-ignore
  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const headers = [
    { label: "Id", key: "id" },
    { label: "Name", key: "name" },
    { label: "Straße", key: "street" },
    { label: "Hausnummer", key: "streetNumber" },
    { label: "PLZ", key: "zip" },
    { label: "Ort", key: "city" },
    { label: "Gesperrt", key: "locked" },
  ];

  const csvReport = {
    data: customers,
    headers: headers,
    filename: "Customers_Export.csv",
  };

  const handleChangeLimit = (dataKey: any) => {
    setPage(1);
    setLimit(dataKey);
  };

  if (!paginatedResult) {
    return (
      <>
        <Root />
        <Content>
          <Loader content='wird geladen...' />
        </Content>
      </>
    );
  } else {
    return (
      <div className='Customer'>
        <Root />
        <Content>
          <h1>Kunden</h1>
          <div
            className='actions'
            style={{ marginBottom: 20, display: "flex", gridGap: 20 }}
          >
            <Button href='/customers/create'>Neuen Kunden anlegen</Button>
            <Search
              onChange={(e) => handleSearchQuery(e)}
              placeholder='Nach Namen suchen'
            />
          </div>
          <div>
            <Table
              virtualized
              data={getSortedData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              cellBordered
              loading={loading}
              autoHeight
            >
              <Column align='center' width={70} sortable>
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey='id' />
              </Column>
              <Column align='center' width={150} sortable resizable>
                <HeaderCell>Name</HeaderCell>
                <EditableCell dataKey='name' onChange={handleChange} />
              </Column>
              <Column align='center' width={150}>
                <HeaderCell>Straße</HeaderCell>
                <EditableCell dataKey='street' onChange={handleChange} />
              </Column>
              <Column align='center' width={70}>
                <HeaderCell>Hausnummer</HeaderCell>
                <EditableCell dataKey='streetNumber' />
              </Column>
              <Column align='center' width={70}>
                <HeaderCell>PLZ</HeaderCell>
                <EditableCell dataKey='zip' />
              </Column>
              <Column align='center' width={150} resizable>
                <HeaderCell>Ort</HeaderCell>
                <EditableCell dataKey='city' />
              </Column>
              <Column align='center' width={150} resizable>
                <HeaderCell>Gesperrt</HeaderCell>
                <EditableCell
                  dataKey='isLockedText'
                  datakeyedit='locked'
                  onChange={handleChange}
                  usecheckbox={true}
                />
              </Column>
              <Column width={200}>
                <HeaderCell>Aktionen</HeaderCell>
                <ActionCell
                  dataKey='id'
                  onClick={handleEditState}
                  onDelete={handleDeleteState}
                />
              </Column>
            </Table>
            {loading && <Loader content='wird geladen...' />}
          </div>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size='xs'
              layout={["total", "-", "limit", "|", "pager"]}
              total={paginatedResult!.totalItems}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
          <CSVLink style={{ marginTop: 8 }} {...csvReport}>
            Ansicht als CSV herunterladen
          </CSVLink>
        </Content>
      </div>
    );
  }
};

export default Customers;
