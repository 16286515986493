import React from "react";
import { CheckPicker, SelectPicker } from "rsuite";

interface DataType {
  id: number;
  name?: string;
  street?: string;
  streetNumber?: string;
  zip?: string;
  city?: string;
  analyseNumber?: string;
}

interface Props {
  data: [];
  onChange: (e: any) => void;
  defaultValue: number | string | [];
  multiple?: boolean;
  placeholder?: string;
  style?: any;
}

const Picker = ({
  data,
  onChange,
  defaultValue,
  multiple,
  placeholder,
  style,
}: Props) => {
  const dataItems =
    data && data.length >= 1
      ? data.map((item: DataType) => ({
          label:
            item.name && item.street
              ? `${item.name} - ${item.street} ${item.streetNumber}, ${item.zip} ${item.city}`
              : item.name
              ? item.name
              : item.analyseNumber
              ? item.analyseNumber
              : item.id,
          value: item.id,
        }))
      : [];

  if (!multiple) {
    return (
      <SelectPicker
        data={dataItems}
        defaultValue={defaultValue}
        onChange={(e) => onChange(e)}
        placeholder={placeholder}
        style={style}
      />
    );
  } else {
    return (
      <CheckPicker
        data={dataItems}
        //@ts-ignore
        defaultValue={defaultValue}
        onChange={(e) => onChange(e)}
        placeholder={placeholder}
        style={style}
      />
    );
  }
};

export default Picker;
