import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  ButtonToolbar,
  InputGroup,
  FlexboxGrid,
  Panel,
  toaster,
  Message,
} from "rsuite";

import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";

import "./login.scss";
import FormGroup from "rsuite/esm/FormGroup";
import FormControl from "rsuite/esm/FormControl";

const Registration = () => {
  const [visible, setVisible] = useState(false);

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const handlePasswordVisibility = () => {
    setVisible(!visible);
  };

  const handleSubmit = async () => {
    try {
      const body = {
        name: name,
        email: email,
        password: password,
        isAdmin: false,
      };
      const api = process.env.REACT_APP_API_URL ?? "http://localhost:8080";

      const signUp = await fetch(`${api}/api/auth/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      if (signUp.ok) {
        const data = await signUp.json();
        toaster.push(
          <Message showIcon type='success' header='Erfolgreich'>
            Der User {data.email} wurde erfolgreich registriert.
          </Message>
        );
      }

      if (signUp.status === 400) {
        toaster.push(
          <Message showIcon type='error' header='Fehlgeschlagen'>
            Bei der Registrierung ist ein Fehler aufgetreten.
          </Message>
        );
      }
    } catch (e) {
      console.log(e);
      toaster.push(
        <Message showIcon type='error' header='Fehlgeschlagen'>
          Bei der Registrierung ist ein Fehler aufgetreten.
        </Message>
      );
    }
  };

  return (
    <div className='login'>
      <FlexboxGrid justify='center'>
        <FlexboxGrid.Item
          colspan={4}
          style={{ background: "#fff", marginTop: "7%", borderRadius: "10px" }}
        >
          <Panel bordered style={{ borderRadius: "10px" }}>
            <Form fluid onSubmit={() => handleSubmit()}>
              <FormGroup>
                <Form.ControlLabel>Name</Form.ControlLabel>
                <FormControl name='name' onChange={(e) => setName(e)} />
                <Form.HelpText>Vorname Nachname</Form.HelpText>
              </FormGroup>
              <FormGroup>
                <Form.ControlLabel>Email</Form.ControlLabel>
                <FormControl
                  name='email'
                  type='email'
                  onChange={(e) => setEmail(e)}
                />
              </FormGroup>
              <FormGroup>
                <Form.ControlLabel>Password</Form.ControlLabel>
                <InputGroup inside>
                  <Input
                    type={visible ? "text" : "password"}
                    onChange={(e) => setPassword(e)}
                  />
                  <InputGroup.Button onClick={handlePasswordVisibility}>
                    {visible ? <EyeIcon /> : <EyeSlashIcon />}
                  </InputGroup.Button>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <ButtonToolbar style={{ justifyContent: "flex-end" }}>
                  <Button appearance='link' href='/'>
                    Zum Login
                  </Button>
                  <Button appearance='primary' type='submit'>
                    Registrieren
                  </Button>
                </ButtonToolbar>
              </FormGroup>
            </Form>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
};

export default Registration;
