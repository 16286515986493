import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Button, Loader, Pagination, Table } from "rsuite";
import Content from "../../components/content/content";
import { Search } from "../../components/Search/Search";
import ActionCell from "../../components/Table/ActionCell";
import EditableCell from "../../components/Table/EditableCell";
import {
  deleteDeliveryAddress,
  getAllDeliveryAddresses,
  updateDeliveryAddress,
} from "../../functions/deliveryAddresses";
import DeliveryAddress from "../../interfaces/deliveryAddress";
import PaginatedResult from "../../interfaces/paginatedResult";

import Root from "../../routes/navigation";

import "./deliveryAddresses.scss";

const DeliveryAddresses = () => {
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const { Column, HeaderCell, Cell } = Table;
  const [paginatedResult, setPaginatedResult] = useState<
    PaginatedResult<DeliveryAddress>
  >();

  const [deliveryAddresses, setDeliveryAddresses] = useState<DeliveryAddress[]>(
    []
  );

  const [query, setQuery] = useState("");

  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleChange = (id: number, key: number, value: any) => {
    const nextData = Object.assign([], deliveryAddresses);

    //@ts-ignore
    nextData.find((item) => item.id === id)[key] = value;

    setDeliveryAddresses(nextData);
  };

  const handleEditState = (id: number) => {
    const nextData = Object.assign([], deliveryAddresses);
    //@ts-ignore
    const activeItem = nextData.find((item) => item.id === id);
    //@ts-ignore
    activeItem.status = activeItem.status ? null : "EDIT";
    setDeliveryAddresses(nextData);
    //@ts-ignore
    if (!activeItem.status) {
      handleUpdate(id);
    }
  };

  const handleDeleteState = async (id: number) => {
    const nextData = Object.assign([], deliveryAddresses);
    //@ts-ignore
    const activeItem = nextData.find((item) => item.id === id);

    nextData.splice(nextData.indexOf(activeItem!), 1);

    setDeliveryAddresses(nextData);

    await deleteDeliveryAddress(id);
    window.location.href = "/deliveryAddresses";
  };

  useEffect(() => {
    setLoading(true);
    const getDeliveryAddresses = getAllDeliveryAddresses(
      page,
      limit,
      query,
      sortType,
      sortColumn
    );
    if (getDeliveryAddresses) {
      getDeliveryAddresses
        .then((data: PaginatedResult<DeliveryAddress>) => {
          setPaginatedResult(data);
          setDeliveryAddresses(data.items);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [page, limit, query, sortColumn, sortType]);

  const handleUpdate = async (id: number) => {
    const deliveryAddressToUpdate = deliveryAddresses.find(
      (deliveryAddress) => deliveryAddress.id === id
    );
    const body = {
      deliveryAddressName: deliveryAddressToUpdate!.deliveryAddressName!,
      name: `${deliveryAddressToUpdate!
        .deliveryAddressName!} - ${deliveryAddressToUpdate!
        .street!} ${deliveryAddressToUpdate!
        .streetNumber!}, ${deliveryAddressToUpdate!.zip!.toString()} ${deliveryAddressToUpdate!
        .city!}`,
      street: deliveryAddressToUpdate!.street!,
      streetNumber: deliveryAddressToUpdate!.streetNumber!,
      zip: deliveryAddressToUpdate!.zip!.toString(),
      city: deliveryAddressToUpdate!.city!,
    };
    updateDeliveryAddress(id, body);
  };

  const getSortedData = () => {
    return deliveryAddresses;
  };

  const handleSearchQuery = async (value: string) => {
    setQuery(value);
    setPage(1);
  };

  //@ts-ignore
  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const headers = [
    { label: "Id", key: "id" },
    { label: "Herkunftsname", key: "deliveryAddressName" },
    { label: "Name", key: "name" },
    { label: "Straße", key: "street" },
    { label: "Hausnummer", key: "streetNumber" },
    { label: "PLZ", key: "zip" },
    { label: "Ort", key: "city" },
  ];

  const csvReport = {
    data: deliveryAddresses,
    headers: headers,
    filename: "DeliveryAddresses_Export.csv",
  };

  const handleChangeLimit = (dataKey: any) => {
    setPage(1);
    setLimit(dataKey);
  };

  if (!paginatedResult) {
    return (
      <>
        <Root />
        <Content>
          <Loader content='wird geladen...' />
        </Content>
      </>
    );
  } else {
    return (
      <div className='DeliveryAddress'>
        <Root />
        <Content>
          <h1>Herkunftsadressen</h1>
          <div
            className='actions'
            style={{ marginBottom: 20, display: "flex", gridGap: 20 }}
          >
            <Button href='/deliveryAddresses/create'>
              Neue Herkunftsadresse anlegen
            </Button>
            <Search
              onChange={(e) => handleSearchQuery(e)}
              placeholder='Nach Namen suchen'
            />
          </div>
          <div>
            <Table
              virtualized
              data={getSortedData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              cellBordered
              loading={loading}
              autoHeight
            >
              <Column align='center' width={70} sortable>
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey='id' />
              </Column>
              <Column align='center' width={200} resizable sortable>
                <HeaderCell>Herkunftsname</HeaderCell>
                <EditableCell
                  dataKey='deliveryAddressName'
                  onChange={handleChange}
                />
              </Column>
              <Column align='center' width={150} resizable>
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey='name' />
              </Column>
              <Column align='center' width={150} resizable>
                <HeaderCell>Straße</HeaderCell>
                <EditableCell dataKey='street' onChange={handleChange} />
              </Column>
              <Column align='center' width={70}>
                <HeaderCell>Hausnummer</HeaderCell>
                <EditableCell dataKey='streetNumber' onChange={handleChange} />
              </Column>
              <Column align='center' width={70}>
                <HeaderCell>PLZ</HeaderCell>
                <EditableCell dataKey='zip' onChange={handleChange} />
              </Column>
              <Column align='center' width={150} resizable>
                <HeaderCell>Ort</HeaderCell>
                <EditableCell dataKey='city' onChange={handleChange} />
              </Column>
              <Column width={200}>
                <HeaderCell>Aktionen</HeaderCell>
                <ActionCell
                  dataKey='id'
                  onClick={handleEditState}
                  onDelete={handleDeleteState}
                />
              </Column>
            </Table>
            {loading && <Loader content='wird geladen...' />}
          </div>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size='xs'
              layout={["total", "-", "limit", "|", "pager"]}
              total={paginatedResult!.totalItems}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
          <CSVLink style={{ marginTop: 8 }} {...csvReport}>
            Ansicht als CSV herunterladen
          </CSVLink>
        </Content>
      </div>
    );
  }
};

export default DeliveryAddresses;
