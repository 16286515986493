import React from "react";
import { Input, InputGroup } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";

interface Props {
  onChange: (value: string) => void;
  placeholder: string;
}

export const Search = ({ onChange, placeholder }: Props) => {
  const [value, setValue] = React.useState("");

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      onChange(value);
    }
  };

  return (
    <div style={{ width: 300 }}>
      <InputGroup>
        <Input
          onChange={(e) => setValue(e)}
          value={value}
          placeholder={placeholder}
          onKeyPress={(e) => handleKeyPress(e)}
        />
        <InputGroup.Addon
          style={{ cursor: "pointer" }}
          onClick={() => onChange(value)}
        >
          <SearchIcon />
        </InputGroup.Addon>
      </InputGroup>
    </div>
  );
};
